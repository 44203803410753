import { Component, OnInit, Inject } from '@angular/core';
// import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialogbox',
  templateUrl: './dialogbox.component.html',
  styleUrls: ['./dialogbox.component.css']
})
export class DialogboxComponent implements OnInit {

  constructor() { }

  // public dialogRef: MatDialogRef<DialogboxComponent>,
  // @Inject(MAT_DIALOG_DATA) public data: DialogData

  onNoClick(): void {
    // this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}

export interface DialogData {
  animal: string;
  name: string;
}
