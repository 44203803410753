import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { ProjectStage } from '../../models/projectStage.model';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
// import { AuthenticationService } from '../../authentication/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  // projectStage: ProjectStage[] = [];

  constructor(private http: HttpClient) { }

  private marketplaceID = '6757fac0-3cf3-11ea-b216-5569445ae12c';
  private storeID = '8672b940-8c71-11ea-86e3-255bc1a5b15f';
  private user;
  private cartitems;

  public SetCartItems(items) {
    this.cartitems = items;
  };

  public GetLocalCartItems() {
    return this.cartitems;
  }

  public SetUser(user) {
    this.user = user;
  }

  public GetUser() {
    return this.user;
  }
  // subscribe to newsletter
  public SubscribeToNewsletter(emailaddress: string) {
    const config = {
      headers: {}
    };
    return this.http.get(environment.amelie.helpers.url + 'subscribe-newsletter?email=' + emailaddress, config)
      .pipe(map((res =>
        res)));
  }

  public GetimageUrlforSize(url, size) {
    const urlseg = url.split('/');
    return 'https://inventra-media.s3.us-east-2.amazonaws.com/' + urlseg[3] + '/' + urlseg[4] + '/' + urlseg[5] + '/' + size + '/default.jpg';;
  }

  public GetWishlist() {
    const config = {
      headers: {}
    };
    return this.http.get(environment.amelie.helpers.url + 'wishlist?cust_id=' + this.user.id, config)
      .pipe(map((res =>
        res)));
  }

  public RegisterCustomer(user) {
    const config = {
      headers: {}
    };
    return this.http.post(environment.amelie.helpers.url + 'customer', user, config)
      .pipe(map((res =>
        res)));
  }

  public AddtoWishlist(productId: string) {
    const config = {
      headers: {}
    };
    const payload = {
      prod_id: productId,
      cust_id: this.user.id
    }
    return this.http.post(environment.amelie.helpers.url + 'add-to-wishlist', payload, config)
      .pipe(map((res =>
        res)));
  }

  public RemoveFromWishlist(productId: string) {
    const config = {
      headers: {}
    };
    const payload = {
      prod_id: productId,
      cust_id: this.user.id
    }
    return this.http.post(environment.amelie.helpers.url + 'remove-from-wishlist', payload, config)
      .pipe(map((res =>
        res)));
  }

  public GetProductDetails(ids: Array<string>) {
    const config = {
      headers: {
        marketplaceid: this.marketplaceID,
        storeid: this.storeID
      }
    };
    const payload = {
      productIds: ids
    };
    return this.http.post(environment.amelie.marketplace.url + 'marketplace/product/get-bulk', payload, config)
      .pipe(map((res =>
        res)));
  }

  public GetNonSKUProducts() {
    const config = {
      headers: {
        marketplaceid: this.marketplaceID,
        storeid: this.storeID
      }
    };
    return this.http.get(environment.amelie.marketplace.url + 'marketplace/store/non-sku-products', config)
      .pipe(map((res =>
        res)));
  }

  public GetProductByCode(code: string) {
    const config = {
      headers: {
        marketplaceid: this.marketplaceID,
        storeid: this.storeID
      }
    };
    return this.http.get(environment.amelie.marketplace.url + 'marketplace/product/get-by-code?prod_code=' + code, config)
      .pipe(map((res =>
        res)));
  }







  public AddtoCart(productId: string, quantity: number) {
    const config = {
      headers: {}
    };
    const payload = {
      prod_id: productId,
      cust_id: this.user.id,
      qty: quantity
    };
    return this.http.post(environment.amelie.helpers.url + 'add-to-cart', payload, config)
      .pipe(map((res =>
        res)));
  }

  public RemoveFromCart(cartid: string, quantity: number) {
    const config = {
      headers: {}
    };
    const payload = {
      c_id: cartid,
      cust_id: this.user.id,
      qty: quantity
    };
    return this.http.post(environment.amelie.helpers.url + 'remove-from-cart', payload, config)
      .pipe(map((res =>
        res)));
  }

  public GetCartItems() {
    const config = {
      headers: {}
    };
    return this.http.get(environment.amelie.helpers.url + 'cart?cust_id=' + this.user.id, config)
      .pipe(map((res =>
        res)));
  }

  ///////////////////////#########################################
  // Order related

  public GetOrders() {
    const config = {
      headers: {}
    };
    return this.http.get(environment.amelie.helpers.url + 'get-orders?customer_id=' + this.user.id, config)
      .pipe(map((res =>
        res)));
  }

  public AddNewOrder(order: any) {
    const config = {
      headers: {}
    };
    const payload = {
      customer_id: this.user.id,
      order_paymenttype: order.order_paymenttype,
      order_paymentreference: order.order_paymentreference,
      order_status: order.order_status,
      order_address: order.order_address,
      order_items: order.order_items,
      order_paymentbreakdown: order.order_paymentbreakdown,
    };
    return this.http.post(environment.amelie.helpers.url + 'add-order', payload, config)
      .pipe(map((res =>
        res)));
  }

  // // project stages
  // GetProjectStage(tocken: string) {
  //   const config = {
  //     headers: {
  //       'Authorization': tocken,
  //       'companyid': localStorage.getItem('userCompanyId')
  //     }
  //   };
  //   return this.http.get(environment.inventra.project_stage.api_url + 'project-stages', config)
  //     .pipe(map((res =>
  //       res['Data'])));
  // }

  // // save project stage
  // SaveProjectStage(projectStage, tocken: string) {
  //   const config = {
  //     headers: {
  //       'Authorization': tocken,
  //       'companyid': localStorage.getItem('userCompanyId')
  //     }
  //   };
  //   return this.http.post(environment.inventra.project_stage.api_url + 'project-stage', projectStage, config)
  //     .pipe(map((res =>
  //       res)));
  // }

  // // update project stage
  // UpdateProjectStage(projectStage, tocken: string) {
  //   const config = {
  //     headers: {
  //       'Authorization': tocken,
  //       'companyid': localStorage.getItem('userCompanyId')
  //     }
  //   };
  //   return this.http.put(environment.inventra.project_stage.api_url + 'project-stage', projectStage, config)
  //     .pipe(map((res =>
  //       res)));
  // }

  // // delete project stage
  // DeleteProjectStage(projStageId, tocken: string) {
  //   const config = {
  //     headers: {
  //       'Authorization': tocken,
  //       'companyid': localStorage.getItem('userCompanyId')
  //     }
  //   };
  //   return this.http.delete(environment.inventra.project_stage.api_url + 'project-stage?ps_id=' + projStageId, config)
  //     .pipe(map((res =>
  //       res)));
  // }

}
