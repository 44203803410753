<main class="main">
  <nav aria-label="breadcrumb" class="breadcrumb-nav border-0 mb-0">
    <div class="container d-flex align-items-center">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/home']">Home</a></li>
        <li class="breadcrumb-item"><a href="javascript:void(0)">Shop</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/products',productCategory]">{{displayCategory}}</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{productCode}}</li>
      </ol>

      <nav class="product-pager ml-auto" aria-label="Product">
        <a class="product-pager-link product-pager-prev" href="#" aria-label="Previous" tabindex="-1">
          <i class="icon-angle-left"></i>
          <span>Prev</span>
        </a>

        <a class="product-pager-link product-pager-next" href="#" aria-label="Next" tabindex="-1">
          <span>Next</span>
          <i class="icon-angle-right"></i>
        </a>
      </nav><!-- End .pager-nav -->
    </div><!-- End .container -->
  </nav><!-- End .breadcrumb-nav -->

  <div class="page-content">
    <div class="container">
      <div class="product-details-top">
        <div class="row">
          <div class="col-md-6">
            <div class="product-gallery product-gallery-vertical">
              <div class="row">
                <figure class="product-main-image">

                  <img id="product-zoom" [src]="defaultImage" data-zoom-image="assets/images/products/single/1-big.jpg"
                    alt="product image">

                  <a href="#" id="btn-product-gallery" class="btn-product-gallery">
                    <i class="icon-arrows"></i>
                  </a>
                </figure><!-- End .product-main-image -->

                <div id="product-zoom-gallery" class="product-image-gallery">


                  <a *ngFor="let image of product.prod_images" (click)="loadImage(image)"
                    class="product-gallery-item active" href="javascript:void(0)">
                    <img [src]="image" alt="product side">
                  </a>


                </div><!-- End .product-image-gallery -->
              </div><!-- End .row -->
            </div><!-- End .product-gallery -->
          </div><!-- End .col-md-6 -->

          <div class="col-md-6">
            <div class="product-details">
              <h1 class="product-title">{{product.prod_name}}</h1><!-- End .product-title -->

              <!-- <div class="ratings-container">
                <div class="ratings">
                  <div class="ratings-val" style="width: 80%;"></div>
                </div>
                <a class="ratings-text" href="#product-review-link" id="review-link">( 2 Reviews )</a>
              </div> -->

              <div class="product-price">
                LKR {{product.prod_pricewotax}}
              </div><!-- End .product-price -->

              <div class="product-content">
                <p>{{product.prod_description}} </p>
              </div><!-- End .product-content -->

              <div class="details-filter-row details-row-size">
                <label>Color:</label>

                <div class="product-nav product-nav-thumbs">
                  <a href="#" class="active">
                    <img src="assets/images/products/single/1-thumb.jpg" alt="product desc">
                  </a>
                  <a href="#">
                    <img src="assets/images/products/single/2-thumb.jpg" alt="product desc">
                  </a>
                </div><!-- End .product-nav -->
              </div><!-- End .details-filter-row -->

              <div class="details-filter-row details-row-size">
                <label for="size">Size:</label>
                <div class="select-custom">
                  <select name="size" id="size" class="form-control">
                    <option value="#" selected="selected">Select a size</option>
                    <option value="s">Small</option>
                    <option value="m">Medium</option>
                    <option value="l">Large</option>
                    <option value="xl">Extra Large</option>
                  </select>
                </div><!-- End .select-custom -->

                <a href="#" class="size-guide"><i class="icon-th-list"></i>size guide</a>
              </div><!-- End .details-filter-row -->

              <div class="details-filter-row details-row-size">
                <label for="qty">Qty:</label>
                <div class="product-details-quantity">
                  <input type="number" id="qty" class="form-control" value="1" min="1" max="10" step="1"
                    data-decimals="0" required>
                </div><!-- End .product-details-quantity -->
              </div><!-- End .details-filter-row -->

              <div class="product-details-action">
                <a href="javascript:void(0)" (click)="addtocart()" class="btn-product btn-cart">
                  <span *ngIf="isloading === false">add to cart</span>
                  <img *ngIf="isloading === true" style="width:15px; height:15px;" src="assets/loader-white.svg" alt="">
                </a>

                <div class="details-action-wrapper">
                  <a href="#" class="btn-product btn-wishlist" title="Wishlist"><span>Add to Wishlist</span></a>
                  <!-- <a href="#" class="btn-product btn-compare" title="Compare"><span>Add to Compare</span></a> -->
                </div><!-- End .details-action-wrapper -->
              </div><!-- End .product-details-action -->

              <div class="product-details-footer">
                <div class="product-cat">
                  <span>Category:</span>
                  <a href="#">Women</a>,
                  <a href="#">Dresses</a>,
                  <a href="#">Yellow</a>
                </div><!-- End .product-cat -->

                <div class="social-icons social-icons-sm">
                  <span class="social-label">Share:</span>
                  <a href="#" class="social-icon" title="Facebook" target="_blank"><i class="icon-facebook-f"></i></a>
                  <a href="#" class="social-icon" title="Twitter" target="_blank"><i class="icon-twitter"></i></a>
                  <a href="#" class="social-icon" title="Instagram" target="_blank"><i class="icon-instagram"></i></a>
                  <a href="#" class="social-icon" title="Pinterest" target="_blank"><i class="icon-pinterest"></i></a>
                </div>
              </div><!-- End .product-details-footer -->
            </div><!-- End .product-details -->
          </div><!-- End .col-md-6 -->
        </div><!-- End .row -->
      </div><!-- End .product-details-top -->

      <div class="product-details-tab">
        <ul class="nav nav-pills justify-content-center" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="product-desc-link" data-toggle="tab" href="#product-desc-tab" role="tab"
              aria-controls="product-desc-tab" aria-selected="true">Description</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="product-shipping-link" data-toggle="tab" href="#product-shipping-tab" role="tab"
              aria-controls="product-shipping-tab" aria-selected="false">Shipping & Returns</a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane fade show active" id="product-desc-tab" role="tabpanel"
            aria-labelledby="product-desc-link">
            <div class="product-desc-content">
              <h3>Product Information</h3>
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros.
                Nullam malesuada erat ut turpis. Suspendisse urna viverra non, semper suscipit, posuere a, pede. Donec
                nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim
                pellentesque felis. Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula
                vulputate sem tristique cursus. </p>
              <ul>
                <li>Nunc nec porttitor turpis. In eu risus enim. In vitae mollis elit. </li>
                <li>Vivamus finibus vel mauris ut vehicula.</li>
                <li>Nullam a magna porttitor, dictum risus nec, faucibus sapien.</li>
              </ul>

              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros.
                Nullam malesuada erat ut turpis. Suspendisse urna viverra non, semper suscipit, posuere a, pede. Donec
                nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim
                pellentesque felis. Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula
                vulputate sem tristique cursus. </p>
            </div><!-- End .product-desc-content -->
          </div><!-- .End .tab-pane -->
          <div class="tab-pane fade" id="product-shipping-tab" role="tabpanel" aria-labelledby="product-shipping-link">
            <div class="product-desc-content">
              <h3>Delivery & returns</h3>
              <p>We deliver to over 100 countries around the world. For full details of the delivery options we offer,
                please view our <a href="#">Delivery information</a><br>
                We hope you’ll love every purchase, but if you ever need to return an item you can do so within a month
                of receipt. For full details of how to make a return, please view our <a href="#">Returns
                  information</a></p>
            </div><!-- End .product-desc-content -->
          </div><!-- .End .tab-pane -->


        </div><!-- End .tab-content -->
      </div><!-- End .product-details-tab -->

      <h2 class="title text-center mb-4">You May Also Like</h2><!-- End .title text-center -->

      <div class="owl-carousel owl-simple carousel-equal-height carousel-with-shadow" data-toggle="owl"
        data-owl-options='{
                  "nav": false,
                  "dots": true,
                  "margin": 20,
                  "loop": false,
                  "responsive": {
                      "0": {
                          "items":1
                      },
                      "480": {
                          "items":2
                      },
                      "768": {
                          "items":3
                      },
                      "992": {
                          "items":4
                      },
                      "1200": {
                          "items":4,
                          "nav": true,
                          "dots": false
                      }
                  }
              }'>
        <div class="product product-7 text-center">
          <figure class="product-media">
            <span class="product-label label-new">New</span>
            <a href="product.html">
              <img src="assets/images/products/product-4.jpg" alt="Product image" class="product-image">
            </a>

            <div class="product-action-vertical">
              <a href="#" class="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
              <a href="popup/quickView.html" class="btn-product-icon btn-quickview" title="Quick view"><span>Quick
                  view</span></a>
              <a href="#" class="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a>
            </div><!-- End .product-action-vertical -->

            <div class="product-action">
              <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
            </div><!-- End .product-action -->
          </figure><!-- End .product-media -->

          <div class="product-body">
            <div class="product-cat">
              <a href="#">Women</a>
            </div><!-- End .product-cat -->
            <h3 class="product-title"><a href="product.html">Brown paperbag waist <br>pencil skirt</a></h3>
            <!-- End .product-title -->
            <div class="product-price">
              LKR 60.00
            </div><!-- End .product-price -->
            <div class="ratings-container">
              <div class="ratings">
                <div class="ratings-val" style="width: 20%;"></div><!-- End .ratings-val -->
              </div><!-- End .ratings -->
              <span class="ratings-text">( 2 Reviews )</span>
            </div><!-- End .rating-container -->

            <div class="product-nav product-nav-thumbs">
              <a href="#" class="active">
                <img src="assets/images/products/product-4-thumb.jpg" alt="product desc">
              </a>
              <a href="#">
                <img src="assets/images/products/product-4-2-thumb.jpg" alt="product desc">
              </a>

              <a href="#">
                <img src="assets/images/products/product-4-3-thumb.jpg" alt="product desc">
              </a>
            </div>
          </div><!-- End .product-body -->
        </div><!-- End .product -->

        <div class="product product-7 text-center">
          <figure class="product-media">
            <span class="product-label label-out">Out of Stock</span>
            <a href="product.html">
              <img src="assets/images/products/product-6.jpg" alt="Product image" class="product-image">
            </a>

            <div class="product-action-vertical">
              <a href="#" class="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
              <a href="popup/quickView.html" class="btn-product-icon btn-quickview" title="Quick view"><span>Quick
                  view</span></a>
              <a href="#" class="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a>
            </div><!-- End .product-action-vertical -->

            <div class="product-action">
              <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
            </div><!-- End .product-action -->
          </figure><!-- End .product-media -->

          <div class="product-body">
            <div class="product-cat">
              <a href="#">Jackets</a>
            </div><!-- End .product-cat -->
            <h3 class="product-title"><a href="product.html">Khaki utility boiler jumpsuit</a></h3>
            <!-- End .product-title -->
            <div class="product-price">
              <span class="out-price">LKR 120.00</span>
            </div><!-- End .product-price -->
            <div class="ratings-container">
              <div class="ratings">
                <div class="ratings-val" style="width: 80%;"></div><!-- End .ratings-val -->
              </div><!-- End .ratings -->
              <span class="ratings-text">( 6 Reviews )</span>
            </div><!-- End .rating-container -->
          </div><!-- End .product-body -->
        </div><!-- End .product -->

        <div class="product product-7 text-center">
          <figure class="product-media">
            <span class="product-label label-top">Top</span>
            <a href="product.html">
              <img src="assets/images/products/product-11.jpg" alt="Product image" class="product-image">
            </a>

            <div class="product-action-vertical">
              <a href="#" class="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
              <a href="popup/quickView.html" class="btn-product-icon btn-quickview" title="Quick view"><span>Quick
                  view</span></a>
              <a href="#" class="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a>
            </div><!-- End .product-action-vertical -->

            <div class="product-action">
              <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
            </div><!-- End .product-action -->
          </figure><!-- End .product-media -->

          <div class="product-body">
            <div class="product-cat">
              <a href="#">Shoes</a>
            </div><!-- End .product-cat -->
            <h3 class="product-title"><a href="product.html">Light brown studded Wide fit wedges</a></h3>
            <!-- End .product-title -->
            <div class="product-price">
              LKR 110.00
            </div><!-- End .product-price -->
            <div class="ratings-container">
              <div class="ratings">
                <div class="ratings-val" style="width: 80%;"></div><!-- End .ratings-val -->
              </div><!-- End .ratings -->
              <span class="ratings-text">( 1 Reviews )</span>
            </div><!-- End .rating-container -->

            <div class="product-nav product-nav-thumbs">
              <a href="#" class="active">
                <img src="assets/images/products/product-11-thumb.jpg" alt="product desc">
              </a>
              <a href="#">
                <img src="assets/images/products/product-11-2-thumb.jpg" alt="product desc">
              </a>

              <a href="#">
                <img src="assets/images/products/product-11-3-thumb.jpg" alt="product desc">
              </a>
            </div><!-- End .product-nav -->
          </div><!-- End .product-body -->
        </div><!-- End .product -->

        <div class="product product-7 text-center">
          <figure class="product-media">
            <a href="product.html">
              <img src="assets/images/products/product-10.jpg" alt="Product image" class="product-image">
            </a>

            <div class="product-action-vertical">
              <a href="#" class="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
              <a href="popup/quickView.html" class="btn-product-icon btn-quickview" title="Quick view"><span>Quick
                  view</span></a>
              <a href="#" class="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a>
            </div><!-- End .product-action-vertical -->

            <div class="product-action">
              <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
            </div><!-- End .product-action -->
          </figure><!-- End .product-media -->

          <div class="product-body">
            <div class="product-cat">
              <a href="#">Jumpers</a>
            </div><!-- End .product-cat -->
            <h3 class="product-title"><a href="product.html">Yellow button front tea top</a></h3>
            <!-- End .product-title -->
            <div class="product-price">
              LKR 56.00
            </div><!-- End .product-price -->
            <div class="ratings-container">
              <div class="ratings">
                <div class="ratings-val" style="width: 0%;"></div><!-- End .ratings-val -->
              </div><!-- End .ratings -->
              <span class="ratings-text">( 0 Reviews )</span>
            </div><!-- End .rating-container -->
          </div><!-- End .product-body -->
        </div><!-- End .product -->

        <div class="product product-7 text-center">
          <figure class="product-media">
            <a href="product.html">
              <img src="assets/images/products/product-7.jpg" alt="Product image" class="product-image">
            </a>

            <div class="product-action-vertical">
              <a href="#" class="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
              <a href="popup/quickView.html" class="btn-product-icon btn-quickview" title="Quick view"><span>Quick
                  view</span></a>
              <a href="#" class="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a>
            </div><!-- End .product-action-vertical -->

            <div class="product-action">
              <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
            </div><!-- End .product-action -->
          </figure><!-- End .product-media -->

          <div class="product-body">
            <div class="product-cat">
              <a href="#">Jeans</a>
            </div><!-- End .product-cat -->
            <h3 class="product-title"><a href="product.html">Blue utility pinafore denim dress</a></h3>
            <!-- End .product-title -->
            <div class="product-price">
              LKR 76.00
            </div><!-- End .product-price -->
            <div class="ratings-container">
              <div class="ratings">
                <div class="ratings-val" style="width: 20%;"></div><!-- End .ratings-val -->
              </div><!-- End .ratings -->
              <span class="ratings-text">( 2 Reviews )</span>
            </div><!-- End .rating-container -->
          </div><!-- End .product-body -->
        </div><!-- End .product -->
      </div><!-- End .owl-carousel -->
    </div><!-- End .container -->
  </div><!-- End .page-content -->
</main><!-- End .main -->
