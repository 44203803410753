import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HelperService } from '../services/helpers.service';
import { Product, ImageDisplay } from '../models/product.model';
import { filter } from 'rxjs/operators';
import { MessageService } from '../services/message.service';
import { NotificationService } from '../services/notification.service';
declare var $: any;

@Component({
  selector: 'app-productdetails',
  templateUrl: './productdetails.component.html',
  styleUrls: ['./productdetails.component.css']
})
export class ProductdetailsComponent implements OnInit, OnDestroy {

  constructor(
    private activatedrouter: ActivatedRoute,
    private router: Router,
    private helperService: HelperService,
    private msgService: MessageService,
    private notifyService: NotificationService
  ) { }

  sub;
  productCategory: string;
  productCode: string;
  product;
  displayCategory: string;
  defaultImage = 'assets/product-loader.svg';
  bigImage = 'assets/product-loader.svg';
  isloading;

  ngOnInit(): void {
    this.isloading = false;
    this.product = {};
    this.product.prod_images = [];

    this.sub = this.activatedrouter.paramMap.subscribe(params => {
      this.productCategory = params.get('category');
      this.productCode = params.get('productcode');
      console.log('Route Change: Product Code: ', this.productCode);
    });
    console.log('Product Category: ' + this.productCategory);
    console.log('Product Code: ' + this.productCode);

    this.loadproductDetailsbycode();
    this.setDisplayCategory();
  }

  getProduct() {
    return this.product;
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy() {
    this.sub.unsubscribe();
    $('.zoomContainer').remove();
  }

  onBack(): void {
    this.router.navigate(['products']);
  }

  loadproductDetailsbycode() {
    // this.isloading = true;
    this.helperService.GetProductByCode(this.productCode).subscribe(
      (res) => {
        debugger
        this.product = res['Data'];

        // $('#product-zoom').data('zoom-image', this.product.prod_images[0]).elevateZoom({
        //   gallery: 'product-zoom-gallery',
        //   galleryActiveClass: 'active',
        //   zoomType: 'inner',
        //   cursor: 'crosshair',
        //   zoomWindowFadeIn: 400,
        //   zoomWindowFadeOut: 400,
        //   responsive: true
        // });

        // $('.zoomContainer').remove();
        // $('#product-zoom').removeData('elevateZoom');
        // $('#product-zoom').attr('data-zoom-image', this.product.prod_images[0]);

        // this.defaultImage = this.helperService.GetimageUrlforSize(this.product.prod_images[0], '500x500');
        this.loadImage(this.product.prod_images[0]);
        this.initializeGallery();
        // this.isloading = false;
        // this.notifyService.showSuccess('Email added to our list!', '');
        // this.isloading = false;
        // this.done = true;
      },
      (err) => {
        console.log(err);
        // this.isloading = false;
        // this.notifyService.showError('Data shown successfully !!', 'ItSolutionStuff.com');
        // this.isloading = false;
      }
    );

  }

  loadImage(image) {
    this.defaultImage = this.helperService.GetimageUrlforSize(image, '500x500');
    this.bigImage = this.helperService.GetimageUrlforSize(image, '1000x1000');
    $('#product-zoom').data('zoom-image', this.bigImage).elevateZoom({
      gallery: 'product-zoom-gallery',
      galleryActiveClass: 'active',
      zoomType: 'inner',
      cursor: 'crosshair',
      zoomWindowFadeIn: 400,
      zoomWindowFadeOut: 400,
      responsive: true
    });
  }

  initializeGallery() {
    // setting the image zoom parts

    // On click change thumbs active item
    $('.product-gallery-item').on('click', function (e) {
      $('#product-zoom-gallery').find('a').removeClass('active');
      $(this).addClass('active');

      e.preventDefault();
    });

    // re set the images on the gallery
    const newarray = [];
    this.product.prod_images.forEach(img => {
      newarray.push(this.helperService.GetimageUrlforSize(img, '250x250'));
    });
    this.product.prod_images = newarray;

    debugger
    const ez = $('#product-zoom').data('elevateZoom');
    // const ss = ez.getGalleryList();
    const ss = [];
    const prod = this.getProduct();
    for (let i = 0; i < prod.prod_images.length; i++) {
      ss.push({ src: this.helperService.GetimageUrlforSize(prod.prod_images[i], '1000x1000'), title: 'Product Image ' + (i + 1) });
    }

    // Open popup - product images
    $('#btn-product-gallery').on('click', function (e) {
      if ($.fn.magnificPopup) {
        $.magnificPopup.open({
          items: ss,
          type: 'image',
          gallery: {
            enabled: true
          },
          fixedContentPos: false,
          removalDelay: 600,
          closeBtnInside: false
        }, 0);

        e.preventDefault();
      }
    });


  }

  addtocart() {
    this.msgService.sendMessage('set_cart_loading', '');
    this.isloading = true;
    this.product.qty = 1;
    this.helperService.AddtoCart(this.product.prod_id, 1).subscribe(
      (res) => {
        this.msgService.sendMessage('add_to_cart', this.product);
        this.msgService.sendMessage('increment_cart', '');
        this.notifyService.showSuccess('Item added to cart!', '');
        this.isloading = false;
      },
      (err) => {
        console.log(err);
        this.msgService.sendMessage('stop_cart_loading', '');
        this.notifyService.showError('Error when adding item to cart', '');
        this.isloading = false;
      }
    );
  }

  setDisplayCategory() {
    switch (this.productCategory) {
      case 'dresses': this.displayCategory = 'Dresses'; break;
      case 'tops': this.displayCategory = 'Tops'; break;
      case 'pants': this.displayCategory = 'Pants'; break;
      case 'skirts': this.displayCategory = 'Skirts'; break;
      case 'jumpsuite': this.displayCategory = 'Jump-Suites'; break;
    }
  }

}
