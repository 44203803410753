<main class="main">
  <div class="page-header text-center" style="background-image: url('assets/images/page-header-bg.jpg')">
    <div class="container">
      <h1 class="page-title">My Account<span>at Amélie Online</span></h1>
    </div><!-- End .container -->
  </div><!-- End .page-header -->
  <nav aria-label="breadcrumb" class="breadcrumb-nav mb-3">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/home']">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">My Account</li>
      </ol>
    </div><!-- End .container -->
  </nav><!-- End .breadcrumb-nav -->

  <div class="page-content">
    <div class="dashboard">
      <div class="container">
        <div class="row">
          <aside class="col-md-4 col-lg-3">
            <ul class="nav nav-dashboard flex-column mb-3 mb-md-0" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" id="tab-dashboard-link" data-toggle="tab" href="#tab-dashboard" role="tab"
                  aria-controls="tab-dashboard" aria-selected="true">Overview</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="tab-orders-link" data-toggle="tab" href="#tab-orders" role="tab"
                  aria-controls="tab-orders" aria-selected="false">Orders</a>
              </li>
              <!-- <li class="nav-item">
                <a class="nav-link" id="tab-downloads-link" data-toggle="tab" href="#tab-downloads" role="tab"
                  aria-controls="tab-downloads" aria-selected="false">Downloads</a>
              </li> -->

              <!-- <li class="nav-item">
                <a class="nav-link" id="tab-account-link" data-toggle="tab" href="#tab-account" role="tab"
                  aria-controls="tab-account" aria-selected="false">Account Details</a>
              </li> -->

              <li class="nav-item">
                <a class="nav-link" id="tab-address-link" data-toggle="tab" href="#tab-address" role="tab"
                  aria-controls="tab-address" aria-selected="false">Delivery Address</a>
              </li>
              <!-- <li class="nav-item">
                <a class="nav-link" href="#">Sign Out</a>
              </li> -->
            </ul>
          </aside><!-- End .col-lg-3 -->

          <div class="col-md-8 col-lg-9">
            <div class="tab-content">

              <div class="tab-pane fade show active" id="tab-dashboard" role="tabpanel"
                aria-labelledby="tab-dashboard-link">
                <h4 class="title mb-3">Overview</h4>


                <form #names="ngForm" (ngSubmit)="updateUser(names)">

                  <div class="row">
                    <div class="col-sm-2">
                      <label>Profile Image</label>
                      <img style="
                      width: 100%;margin: 3px; border-radius: 20px;" src="{{user.photoUrl}}" alt="User Image">
                    </div><!-- End .col-sm-6 -->

                    <div class="col-sm-10">
                      <div class="row">
                        <div class="col-sm-6">
                          <label>First Name</label>
                          <input name="firstName" type="text" [(ngModel)]="user.firstName" class="form-control" required
                            disabled>
                        </div>

                        <div class="col-sm-6">
                          <label>Last Name</label>
                          <input name="lastName" type="text" [(ngModel)]="user.lastName" class="form-control" required
                            disabled>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12">
                          <label>Email address</label>
                          <input name="email" [(ngModel)]="user.email" type="email" class="form-control" required
                            disabled>
                        </div>

                      </div>

                    </div>



                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <label>Phone *</label>
                      <input name="phone" type="tel" class="form-control" required [(ngModel)]="user.phone">
                    </div>
                    <div class="col-sm-4">
                      <label for="gender">Gender</label>
                      <select class="form-control" id="gender" [(ngModel)]="user.gender">
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                        <option value="rathernotsay">Rather not say</option>
                      </select>
                    </div>
                    <div class="col-sm-4">
                      <label>Date of Birth</label>
                      <input class="form-control" type="date" id="dateofbirth" name="dateofbirth"
                        [(ngModel)]="user.dob">
                      <!-- <input name="phone" type="tel" class="form-control" required [(ngModel)]="user.phone"> -->
                    </div>
                  </div>


                  <button type="submit" class="btn btn-outline-primary-2">
                    <span *ngIf="isloading === false">SAVE CHANGES</span>
                    <i *ngIf="isloading === false" class="icon-long-arrow-right"></i>
                    <img *ngIf="isloading === true" style="width:20px; height:20px;" src="assets/loader-white.svg"
                      alt="">
                  </button>

                </form>

                <small>Note - Some fields are not editable since they were provided to us by your social login. (First
                  Name,
                  Last Name, Email and Profile Image)</small>
              </div>


              <div class="tab-pane fade show" id="tab-orders" role="tabpanel" aria-labelledby="tab-orders-link">
                <h4 class="title mb-3">Orders Information</h4>
                <!-- <p>The following are your order details.</p> -->
                <div class="row">
                  <div class="col-md-12">

                    <div class="accordion" id="accordion-1">
                      <div class="card">
                        <div class="card-header" id="heading-1">
                          <h2 class="card-title">
                            <a role="button" data-toggle="collapse" href="#collapse-1" aria-expanded="true"
                              aria-controls="collapse-1">
                              Active Orders
                            </a>
                          </h2>
                        </div>
                        <div id="collapse-1" class="collapse show" aria-labelledby="heading-1"
                          data-parent="#accordion-1">
                          <p style="margin-left: 15px;" *ngIf="activeorders.length === 0">You don't have any active
                            orders.</p>
                          <app-orderitem [order]="item" *ngFor="let item of activeorders"></app-orderitem>

                        </div><!-- End .collapse -->
                      </div><!-- End .card -->

                      <div class="card">
                        <div class="card-header" id="heading-2">
                          <h2 class="card-title">
                            <a class="collapsed" role="button" data-toggle="collapse" href="#collapse-2"
                              aria-expanded="false" aria-controls="collapse-2">
                              Completed Orders
                            </a>
                          </h2>
                        </div><!-- End .card-header -->
                        <div id="collapse-2" class="collapse" aria-labelledby="heading-2" data-parent="#accordion-1">
                          <p style="margin-left: 15px;" *ngIf="pastorders.length === 0">You don't have any completed
                            orders
                            yet.</p>
                          <app-orderitem [order]="item" *ngFor="let item of pastorders"></app-orderitem>

                        </div><!-- End .collapse -->
                      </div><!-- End .card -->
                    </div><!-- End .accordion -->
                  </div>
                </div>
              </div>


              <div class="tab-pane fade" id="tab-address" role="tabpanel" aria-labelledby="tab-address-link">
                <h4 class="title mb-3">Delivery Address</h4>
                <p>The following address will be used on the checkout page by default.</p>

                <div *ngIf="deliveryedit === false" class="row">
                  <div class="col-lg-12">
                    <div class="card card-dashboard">
                      <div class="card-body">
                        <!-- <h3 class="card-title">Delivery Address</h3> -->

                        <p><b>{{user.displayName}}</b><br>
                          {{ user.companyName}}<br>
                          {{ user.addressline01}}<br>
                          {{user.addressline02}}, {{user.city}}, {{user.country}}<br>
                          {{user.phone}}<br>
                          {{user.email}}<br>
                        </p>

                        <p><a href="javascript:void(0)" (click)="deliveryedit = true">Edit Details <i
                              class="icon-edit"></i></a></p>
                      </div>
                    </div>
                  </div>

                </div>

                <div *ngIf="deliveryedit === true" class="row">

                  <div class="col-lg-12">
                    <form #address='ngForm' (ngSubmit)="updateUser(address)">

                      <label>Display Name *</label>
                      <input name="displayName" [(ngModel)]="user.displayName" type="text" class="form-control"
                        required>
                      <small class="form-text">This will be how your name will be displayed in the delivery
                        address.</small>

                      <label>Company Name (Optional)</label>
                      <input name="companyName" type="text" class="form-control" [(ngModel)]="user.companyName">
                      <!--
                      <label>Country *</label>
                      <input type="text" class="form-control" required> -->

                      <label>Street address *</label>
                      <input name="addressline01" type="text" class="form-control"
                        placeholder="House number and Street name" required [(ngModel)]="user.addressline01">
                      <input name="addressline02" type="text" class="form-control"
                        placeholder="Appartments, suite, unit etc ..." required [(ngModel)]="user.addressline02">

                      <div class="row">
                        <div class="col-sm-6">
                          <label>Town / City *</label>
                          <input name="city" type="text" class="form-control" required [(ngModel)]="user.city">
                        </div><!-- End .col-sm-6 -->

                        <div class="col-sm-6">
                          <label>State / County *</label>
                          <input name="country" type="text" class="form-control" required [(ngModel)]="user.country">
                        </div><!-- End .col-sm-6 -->
                      </div><!-- End .row -->

                      <div class="row">
                        <div class="col-sm-6">
                          <label>Postcode / ZIP *</label>
                          <input name="zipcode" type="text" class="form-control" required [(ngModel)]="user.zipcode">
                        </div><!-- End .col-sm-6 -->


                      </div><!-- End .row -->

                      <button type="submit" class="btn btn-primary">
                        <span *ngIf="isloading === false">SAVE CHANGES</span>
                        <i *ngIf="isloading === false" class="icon-long-arrow-right"></i>
                        <img *ngIf="isloading === true" style="width:20px; height:20px;" src="assets/loader-white.svg"
                          alt="">
                      </button>
                      <button (click)="deliveryedit = false" type="button" class="btn btn-outline-primary-2">
                        <span>Cancel</span>
                        <i class="icon-close"></i>
                      </button>


                    </form>
                  </div>





                </div>
              </div><!-- .End .tab-pane -->

              <!-- <div class="tab-pane fade" id="tab-account" role="tabpanel" aria-labelledby="tab-account-link">
                <form #names="ngForm" (ngSubmit)="updateUser(names)">
                  <div class="row">
                    <div class="col-sm-6">
                      <label>First Name</label>
                      <input name="firstName" type="text" [(ngModel)]="user.firstName" class="form-control" required
                        disabled>
                    </div>

                    <div class="col-sm-6">
                      <label>Last Name</label>
                      <input name="lastName" type="text" [(ngModel)]="user.lastName" class="form-control" required
                        disabled>
                    </div>
                  </div>

                  <label>Display Name *</label>
                  <input name="displayName" [(ngModel)]="user.displayName" type="text" class="form-control" required>
                  <small class="form-text">This will be how your name will be displayed in the delivery address.</small>

                  <label>Email address</label>
                  <input name="email" [(ngModel)]="user.email" type="email" class="form-control" required disabled>

                  <button type="submit" class="btn btn-outline-primary-2">
                    <span *ngIf="isloading === false">SAVE CHANGES</span>
                    <i *ngIf="isloading === false" class="icon-long-arrow-right"></i>
                    <img *ngIf="isloading === true" style="width:20px; height:20px;" src="assets/loader-white.svg"
                      alt="">
                  </button>
                </form>
              </div> -->



            </div>
          </div><!-- End .col-lg-9 -->
        </div><!-- End .row -->
      </div><!-- End .container -->
    </div><!-- End .dashboard -->
  </div><!-- End .page-content -->
</main><!-- End .main -->
