import { Component, OnInit, Input } from '@angular/core';
import { HelperService } from 'src/app/services/helpers.service';

@Component({
  selector: 'app-orderitem',
  templateUrl: './orderitem.component.html',
  styleUrls: ['./orderitem.component.css']
})
export class OrderitemComponent implements OnInit {

  constructor(private helperService: HelperService) { }

  @Input() order;

  cartitems;
  progress;
  moredetails;

  count;
  stageone;
  stageonetext;
  stagetwo;
  stagetwotext;
  stagethree;
  stagethreetext;
  stagefour;
  stagefourtext;

  orderProgress;
  orderDisplayDateStage01;

  ngOnInit(): void {


    this.cartitems = [];
    this.cartitems = this.helperService.GetLocalCartItems();
    this.count = 1;
    const d = new Date(this.order.order_datetime_stage01);
    this.orderDisplayDateStage01 = d.toDateString();

    this.setStage(this.order.order_status);
    this.moredetails = false;
  }

  setStage(stage) {
    switch (stage) {
      case 'stage01': {
        this.progress = '13%';
        this.stageone = 'assets/order/design.gif';
        this.stagetwo = 'assets/order/dot.svg';
        this.stagethree = 'assets/order/dot.svg';
        this.stagefour = 'assets/order/dot.svg';

        this.stageonetext = 'Packing Order';
        this.stagetwotext = 'Awaiting Pickup';
        this.stagethreetext = 'Awaiting Delivery';
        this.stagefourtext = 'You';
        this.orderProgress = 'Packing Order';
        break;
      }
      case 'stage02': {
        this.progress = '38%';
        this.stageone = 'assets/order/tick.png';
        this.stagetwo = 'assets/order/place-marker.gif';
        this.stagethree = 'assets/order/dot.svg';
        this.stagefour = 'assets/order/dot.svg';

        this.stageonetext = 'Packed';
        this.stagetwotext = 'Picking up';
        this.stagethreetext = 'Awaiting Delivery';
        this.stagefourtext = 'You';
        this.orderProgress = 'Picking up';
        break;
      }
      case 'stage03': {
        this.progress = '63%';
        this.stageone = 'assets/order/tick.png';
        this.stagetwo = 'assets/order/tick.png';
        this.stagethree = 'assets/order/delivery.gif';
        this.stagefour = 'assets/order/dot.svg';

        this.stageonetext = 'Packed';
        this.stagetwotext = 'Picked up';
        this.stagethreetext = 'On your way';
        this.stagefourtext = 'You';
        this.orderProgress = 'On your way';
        break;
      }
      case 'stage04': {
        this.progress = '100%';
        this.stageone = 'assets/order/tick.png';
        this.stagetwo = 'assets/order/tick.png';
        this.stagethree = 'assets/order/tick.png';
        this.stagefour = 'assets/order/order-completed.gif';

        this.stageonetext = 'Packed';
        this.stagetwotext = 'Picked up';
        this.stagethreetext = 'Reached Destination';
        this.stagefourtext = 'Delivered';
        this.orderProgress = 'Delivered';

        if (this.order.order_datetime_stage04 !== undefined) {
          const d = new Date(this.order.order_datetime_stage04);
          const now = new Date();

          const seconds = (now.getTime() / 1000) - (d.getTime() / 1000);
          if (seconds > 259200) {
            // its more than 3 days
            this.stagefour = 'assets/order/tick.png';
          }
        }

        break;
      }
    }
  }

  next() {
    this.count++;
    this.setStage('stage0' + this.count);
  }

  back() {
    this.count--;
    this.setStage('stage0' + this.count);
  }

}
