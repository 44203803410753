<!-- *ngIf="isloading === true" -->


<div class="product product-7 text-center">
  <!-- <div class="row text-center">
    <div class="col">
      <img class="center" height="50" width="50" src="/assets/loader-primary.svg" alt="loading">
    </div>
  </div> -->


  <figure class="product-media">
    <span class="product-label label-new">New</span>
    <a href="javascript:void(0)" (click)="routeToProductDetails()">
      <!-- <img  src="assets/images/products/product-1.jpg" alt="Product image" class="product-image"> -->
      <img style="height: 350px;width: 100%; object-fit: cover;"
        src="{{displayImage === '' ? 'assets/loader-primary.svg' : displayImage}}" alt="Product image"
        class="product-image">
      <img style="height: 350px;width: 100%; object-fit: cover;" src="{{hoverImage}}" alt="Product image"
        class="product-image-hover">

    </a>

    <div class="product-action-vertical">
      <a href="javascript:void(0)" (click)="checkwishlist()"
        class="btn-product-icon btn-wishlist btn-expandable"><span>add to
          wishlist</span></a>
      <a href="popup/quickView.html" class="btn-product-icon btn-quickview" title="Quick view"><span>Quick
          view</span></a>
    </div><!-- End .product-action -->

    <div class="product-action">
      <a href="javascript:void(0)" (click)="routeToProductDetails()" class="btn-product btn-cart"><span>Shop now</span></a>
    </div>

    <!-- <div class="product-action action-icon-top">
      <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
    </div> -->
  </figure>

  <div class="product-body">
    <div class="product-cat">
      <a href="#">{{displayCategory}}</a>
    </div><!-- End .product-cat -->
    <h3 class="product-title"><a href="javascript:void(0)" (click)="routeToProductDetails()">{{ product.prod_name}}</a>
    </h3>
    <!-- End .product-title -->
    <div class="product-price">
      LKR {{product.prod_pricewotax}}
    </div><!-- End .product-price -->

    <!-- <div class="product-nav product-nav-dots">
      <a href="#" style="background: #cc9966;"><span class="sr-only">Color name</span></a>
      <a href="#" class="active" style="background: #ebebeb;"><span class="sr-only">Color name</span></a>
    </div> -->

    <div *ngIf="product.prod_varient_list.length > 0" class="product-nav product-nav-thumbs">
      <a href="#" class="active">
        <img src="assets/images/products/product-4-thumb.jpg" alt="product desc">
      </a>
      <a href="#">
        <img src="assets/images/products/product-4-2-thumb.jpg" alt="product desc">
      </a>

      <a href="#">
        <img src="assets/images/products/product-4-3-thumb.jpg" alt="product desc">
      </a>
    </div>

  </div>
</div>
