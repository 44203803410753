<header class="header">
  <div class="header-bottom sticky-header">
    <div class="container">
      <div class="header-left">
        <button class="mobile-menu-toggler">
          <span class="sr-only">Toggle mobile menu</span>
          <i class="icon-bars"></i>
        </button>

        <a [routerLink]="['/home']" class="logo">
          <img src="assets/images/amelie-logo.svg" alt="Amelie Logo" width="110" height="20">
        </a>
      </div>
      <div class="header-center">
        <nav class="main-nav">
          <ul class="menu sf-arrows">
            <li>
              <a [routerLink]="['/home']">Home</a>

            </li>
            <li>
              <a [routerLink]="['/products','dresses']">Dresses</a>
            </li>
            <li>
              <a [routerLink]="['/products','tops']">Tops</a>
            </li>
            <li>
              <a [routerLink]="['/products','pants']">Pants</a>
            </li>
            <li>
              <a [routerLink]="['/products','skirts']">Skirts</a>
            </li>
            <li>
              <a [routerLink]="['/products','jumpsuites']">Jump-Suites</a>
            </li>
          </ul><!-- End .menu -->
        </nav><!-- End .main-nav -->
      </div><!-- End .header-left -->

      <div class="header-right">

        <!-- <div class="header-search">
          <a href="#" class="search-toggle" role="button"><i class="icon-search"></i></a>
          <form action="#" method="get">
            <div class="header-search-wrapper">
              <label for="q" class="sr-only">Search</label>
              <input type="search" class="form-control" name="q" id="q" placeholder="Search in..." required>
            </div>
          </form>
        </div> -->

        <!-- [routerLink]="['/wishlist']" -->

        <a href="javascript:void(0)" (click)="navigateToWishList()" class="wishlist-link">
          <i class="icon-heart-o"></i>
          <span *ngIf="user !== null && user.wishlist_count !== undefined && user.wishlist_count > 0 "
            class="wishlist-count">{{user.wishlist_count}}</span>
        </a>

        <!-- <button type="button" class="btn btn-primary" >
          Launch demo modal
        </button> -->

        <div class="dropdown cart-dropdown">
          <a href="javascript:void(0)" (click)="navigateToCart()" class="dropdown-toggle" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
            <i class="icon-shopping-cart"></i>
            <span *ngIf="user !== null && user.cart_count > 0 " class="cart-count">{{user.cart_count}}</span>
            <span *ngIf="user !== null && user.cart_count > 0 && cartloading === false" class="cart-txt">LKR
              {{totalamount}}</span>
            <img height="20" width="20" style="margin-left: 24px; margin-right: 24px;" *ngIf="cartloading === true"
              src="/assets/loader-primary.svg" alt="">
            <!--  -->
          </a>

          <div *ngIf="loggedIn === true" class="dropdown-menu dropdown-menu-right">
            <div class="dropdown-cart-products">

              <!-- <div style="position: absolute;z-index: 1;">
                <img src="/assets/loader-primary.svg" alt="">
              </div> -->

              <div *ngIf="cartloading === false && cartitems.length == 0">
                <div class="row">
                  <p class="center">Your cart is empty!</p>
                </div>
              </div>


              <div *ngFor="let item of cartitems; let i=index;" class="product">
                <div class="product-cart-details">
                  <h4 class="product-title">
                    <a href="javascript:void(0)"
                      [routerLink]="['/products', item.prod_type.toLowerCase(), item.prod_code]">{{item.prod_name}}</a>
                  </h4>

                  <span class="cart-product-info">
                    <span class="cart-product-qty">{{item.qty}}</span>
                    x LKR {{item.prod_pricewotax}}
                  </span>
                </div><!-- End .product-cart-details -->

                <figure class="product-image-container">
                  <a href="javascript:void(0)"
                    [routerLink]="['/products', item.prod_type.toLowerCase(), item.prod_code]" class="product-image">
                    <img style="width: 60px; height: 60px; object-fit: cover;"
                      [src]="item.prod_images === undefined ? 'assets/images/products/product-1.jpg' : item.prod_images[0]"
                      alt="product">
                  </a>
                </figure>
                <a href="javascript:void(0)" (click)="openProductRemoveConfirmationModel(item, i)" class="btn-remove"
                  title="Remove Product"><i class="icon-close"></i></a>
              </div><!-- End .product -->

              <!-- <div class="product">
                <div class="product-cart-details">
                  <h4 class="product-title">
                    <a href="product.html">Blue utility pinafore denim dress</a>
                  </h4>

                  <span class="cart-product-info">
                    <span class="cart-product-qty">1</span>
                    x LKR 76.00
                  </span>
                </div>

                <figure class="product-image-container">
                  <a href="product.html" class="product-image">
                    <img src="assets/images/products/cart/product-2.jpg" alt="product">
                  </a>
                </figure>
                <a href="#" class="btn-remove" title="Remove Product"><i class="icon-close"></i></a>
              </div> -->


            </div><!-- End .cart-product -->

            <div class="dropdown-cart-total">
              <span>Total</span>

              <span class="cart-total-price">LKR {{totalamount}}</span>
            </div><!-- End .dropdown-cart-total -->

            <div class="dropdown-cart-action">
              <button [disabled]="cartloading === true" [routerLink]="['/cart']" class="btn btn-primary">View
                Cart</button>
              <!-- <a [disabled]="cartloading === true" [routerLink]="['/cart']" class="btn btn-primary">View Cart</a> -->
              <button [disabled]="cartloading === true" [routerLink]="['/checkout']"
                class="btn btn-outline-primary-2">Checkout<i class="icon-long-arrow-right"></i></button>
              <!-- <a [routerLink]="['/checkout']" class="btn btn-outline-primary-2"><span>Checkout</span><i
                  class="icon-long-arrow-right"></i></a> -->
            </div><!-- End .dropdown-cart-total -->
          </div>
        </div>

        <div class="dropdown cart-dropdown">

          <!--  -->
          <div *ngIf="loggedIn == true" class="row btn-wrap btn btn-outline-primary btn-round" style="margin: 10px;">
            <img style="width:20px; height: 20px; border-radius: 20px;" [src]="user.photoUrl" alt="user">
            <div>
              <h6 style="margin: 0px;margin-left: 8px;">{{ user.firstName }}</h6>
            </div>
          </div>


          <div *ngIf="loggedIn === true" class="dropdown-menu dropdown-menu-right" style="width: 200px;">
            <div class="dropdown-cart-products">

              <h6><i class="icon-home"></i> My Account</h6>
              <div>
                <a [routerLink]="['/myaccount']" fragment="tab-dashboard" href="javascript:void(0)">Overview</a>
              </div>

              <div>
                <a [routerLink]="['/myaccount']" fragment="tab-orders" href="javascript:void(0)">Orders</a>
              </div>

              <hr class="mb-1 mt-1">

              <div>
                <a [routerLink]="['/wishlist']" href="javascript:void(0)">Wishlist</a>
              </div>

              <div>
                <a [routerLink]="['/cart']" href="javascript:void(0)">Shopping Cart</a>
              </div>

              <hr class="mb-1 mt-1">

              <div>
                <a (click)="openlogoutModal()" href="javascript:void(0)">Log out</a>
              </div>

            </div><!-- End .cart-product -->

          </div>
        </div>


        <div style="margin: 10px;" *ngIf="loggedIn == false" class="btn-wrap">
          <a (click)="openLoginPanel()" class="btn btn-outline-primary btn-round">Log in</a>
        </div>


        <!-- <a   class="btn btn-primary btn-round"
          style="margin-left: 10px;"></a> -->
        <!-- <input style="margin-left: 10px;" *ngIf="loggedIn == false" (click)="signInWithFB()" type="button"
          value="Continue with Facebook" /> -->
        <!--  <img *ngIf="loggedIn == true" src="{{ user.photoUrl }} assets/images/testimonials/user-1.jpg">
          <div *ngIf="loggedIn == true">
            <h4>{{ user.firstName }}</h4>
            <p>{{ user.email }}</p>
          </div> -->
        <!-- <div class="fb-login-button"></div> -->

        <!-- <div *ngIf="loggedIn == false" (click)="signInWithFB()" class="fb-login-button" data-size="medium"
          data-button-type="continue_with" data-layout="default" data-auto-logout-link="false"
          data-use-continue-as="true" data-width=""></div> -->

      </div><!-- End .header-right -->
    </div><!-- End .container -->
  </div><!-- End .header-bottom -->
</header><!-- End .header -->


<div id="loginModal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Connect with you social logins.</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <div class="row" style="margin: 20px;">
          <a href="javascript:void(0)" (click)="signInWithFB()" class="fb newbtn">
            <i class="icon-facebook fa-fw"></i> Continue with Facebook
          </a>
          <a href="javascript:void(0)" (click)="signInWithGoogle()" class="google newbtn">
            <i class="icon-google fa-fw"></i> Continue with Google
          </a>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-primary">Save changes</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div> -->
    </div>
  </div>
</div>


<div id="logoutconfirmationModal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Want to continue?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <div class="row" style="margin: 20px;">
          <p>Are you sure you want to logout?</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="signOut()">Yes</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
      </div>
    </div>
  </div>
</div>


<div id="removeconfirmationModal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Want to continue?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <div class="row" style="margin: 20px;">
          <p>Are you sure you want to remove the item from the cart?</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary"
          (click)="removeCartItem(removingProduct, removingProductIndex)">Yes</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
      </div>
    </div>
  </div>
</div>
