import { Component, OnInit } from '@angular/core';
import { HelperService } from '../services/helpers.service';
import { NgForm } from '@angular/forms';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-comingsoon',
  templateUrl: './comingsoon.component.html',
  styleUrls: ['./comingsoon.component.css']
})
export class ComingsoonComponent implements OnInit {

  public emailaddress: string;
  public isloading = false;
  public done = false;
  constructor(
    private helperService: HelperService,
    private notifyService: NotificationService
  ) { }

  ngOnInit(): void {
  }

  onSubmit(f: NgForm) {
    console.log(f.value);  // { first: '', last: '' }
    console.log(f.valid);  // false
  }

  subscribeNow(f: NgForm) {

    if (f.valid) {
      this.isloading = true;
      this.helperService.SubscribeToNewsletter(f.value.email).subscribe(
        (res) => {
          console.log(res);
          this.notifyService.showSuccess('Email added to our list!', '');
          this.isloading = false;
          this.done = true;
        },
        (err) => {
          console.log(err);
          this.notifyService.showError('Data shown successfully !!', 'ItSolutionStuff.com');
          this.isloading = false;
        }
      );
    }
  }
}
