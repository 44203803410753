import { Component, OnInit } from '@angular/core';
import { AuthService, SocialUser } from 'angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { Router } from '@angular/router';
import { HelperService } from '../services/helpers.service';
import { NotificationService } from '../services/notification.service';
import { Subscription } from 'rxjs';
import { MessageService } from '../services/message.service';
declare var $: any;

@Component({
  selector: 'app-navibar',
  templateUrl: './navibar.component.html',
  styleUrls: ['./navibar.component.css']
})
export class NavibarComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
    private helperService: HelperService,
    private notifyService: NotificationService,
    private msgService: MessageService
  ) { }

  public user: any;
  public loggedIn: boolean;
  message: any = {};
  subscription: Subscription;
  welcomecount = 0;

  // cart related
  cartitems;
  totalamount;
  tempcartitems;
  cartloading;

  // cart removing
  removingProduct;
  removingProductIndex;

  init() {
    this.user = {};
    this.user.wishlist_count = 0;
    this.user.cart_count = 0;
    this.loggedIn = false;
    this.welcomecount = 0;

    this.cartitems = [];
    this.tempcartitems = [];
    this.totalamount = 0;
    this.cartloading = false;
  }

  ngOnInit(): void {
    this.init();

    //debugger
    const tempuser = localStorage.getItem('amelieonline-current-user');
    if (tempuser !== null && tempuser !== 'undefined') {
      this.user = JSON.parse(tempuser);
      this.loggedIn = (this.user != null);
      this.getprofileDetails();
    } else {
      this.authService.authState.subscribe((user) => {
        //debugger
        this.user = user;
        this.loggedIn = (this.user != null);
        this.getprofileDetails();
      });
    }


    this.subscription = this.msgService.getMessage().subscribe(message => {
      if (message.title === 'increment_wishlsit') {
        this.user.wishlist_count++;
        this.helperService.SetUser(this.user);
        localStorage.setItem('amelieonline-current-user', JSON.stringify(this.user));
      }
      if (message.title === 'decrease_wishlsit') {
        this.user.wishlist_count--;
        this.helperService.SetUser(this.user);
        localStorage.setItem('amelieonline-current-user', JSON.stringify(this.user));
      }
      if (message.title === 'add_to_cart') {
        this.getCartDetails();
      }
      if (message.title === 'increment_cart') {
        this.user.cart_count++;
        this.helperService.SetUser(this.user);
        localStorage.setItem('amelieonline-current-user', JSON.stringify(this.user));
      }
      if (message.title === 'decrease_cart') {
        this.user.cart_count--;
        this.helperService.SetUser(this.user);
        localStorage.setItem('amelieonline-current-user', JSON.stringify(this.user));
      }
      if (message.title === 'set_cart_loading') {
        this.cartloading = true;
      }
      if (message.title === 'stop_cart_loading') {
        this.cartloading = false;
      }
      if (message.title === 'cart_updated') {
        this.getCartDetails();
      }
      if (message.title === 'cart_qty_updated') {
        this.cartitems = message.payload;
        this.updateTotals();
        this.msgService.sendMessage('local_cart_updated', this.cartitems);
      }
      if (message.title === 'reset_cart') {
        this.user.cart_count = 0;
        this.cartitems = [];
        this.updateTotals();
        this.helperService.SetUser(this.user);
        localStorage.setItem('amelieonline-current-user', JSON.stringify(this.user));
      }
    });

  }

  getprofileDetails() {
    if (this.loggedIn === true) {
      $('#loginModal').modal('hide');
      this.helperService.SetUser(this.user);
      this.helperService.RegisterCustomer(this.user).subscribe(
        (res) => {
          this.user = res["Data"];
          this.helperService.SetUser(this.user);
          localStorage.setItem('amelieonline-current-user', JSON.stringify(this.user));
          console.log('Current User: ', this.user);
          this.notifyService.showSuccess('Welcome ' + this.user.name, '');
        },
        (err) => {
          console.log(err);
        }
      );
      this.getCartDetails();
    }
  }

  getCartDetails() {
    this.cartloading = true;
    this.helperService.GetCartItems().subscribe(
      (res) => {
        // this.helperService.SetCartItems(res['Data']);
        this.tempcartitems = res['Data'];
        const items = [];
        if (this.tempcartitems.length > 0) {
          res['Data'].forEach((item, index) => {
            if (!items.includes(item.prod_id)) {
              items.push(item.prod_id);
            }
          });
          this.helperService.GetProductDetails(items).subscribe(
            (res) => {
              this.cartitems = [];
              this.totalamount = 0;
              //this.cartitems = res['Data'];
              this.tempcartitems.forEach((tempitem, index) => {
                var temp = res['Data'].filter(function (item) {
                  return item.prod_id === tempitem.prod_id;
                });
                let product = JSON.parse(JSON.stringify(temp));
                product[0].qty = tempitem.qty;
                product[0].c_id = tempitem.c_id;
                const newarray = [];
                product[0].prod_images[0] = this.helperService.GetimageUrlforSize(product[0].prod_images[0], '250x250');
                this.cartitems.push(product[0]);
                //this.totalamount += tempitem.qty * product[0].prod_pricewotax;
              });
              this.notifyService.showInfo('You got ' + this.cartitems.length + (this.cartitems.length === 1 ? ' item' : ' items') + ' in your cart.', '');
              this.updateTotals();
              this.cartloading = false;
              this.helperService.SetCartItems(this.cartitems);
            },
            (err) => {
              console.log(err);
              this.cartloading = false;
            }
          );
        } else {
          this.cartloading = false;
        }

      },
      (err) => {
        this.cartloading = false;
      }
    );
  }

  updateTotals() {
    this.totalamount = 0;
    this.cartitems.forEach((item, index) => {
      this.totalamount += item.prod_pricewotax * item.qty;
    });
  }

  removeCartItem(product, index) {
    this.cartloading = true;
    this.helperService.RemoveFromCart(product.c_id, product.qty).subscribe(
      (res) => {
        this.msgService.sendMessage('decrease_cart', '');
        this.notifyService.showSuccess('Item removed from cart!', '');
        // this.cartitems.splice(index, 1);
        // this.updateTotals();
        this.getCartDetails();
      },
      (err) => {
        console.log(err);
        this.notifyService.showError('Error when removing item to cart', '');
        this.cartloading = false;
      }
    );
    // remove item from array
    debugger
    this.cartitems.splice(index, 1);
    this.updateTotals();
    $('#removeconfirmationModal').modal('hide');
  }

  navigateToWishList() {
    if (this.loggedIn === true) {
      // continue to redirect
      this.router.navigate(['wishlist']);
    } else {
      // display login modal
      this.openLoginPanel();
    }
  }

  navigateToCart() {
    if (this.loggedIn === true) {
      // continue to redirect
      // this.router.navigate(['wishlist']);
    } else {
      // display login modal
      this.openLoginPanel();
    }
  }

  openLoginPanel() {
    $('#loginModal').modal('show');
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    debugger
    this.authService.signOut();
    this.router.navigate(['home']);
    localStorage.removeItem('amelieonline-current-user');
    this.init();
    $('#logoutconfirmationModal').modal('hide');
    location.reload();
  }

  openlogoutModal() {
    $('#logoutconfirmationModal').modal('show');
  }

  openProductRemoveConfirmationModel(product, index) {
    this.removingProduct = product;
    this.removingProductIndex = index;
    $('#removeconfirmationModal').modal('show');
  }
}
