import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HelperService } from './services/helpers.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private router: Router, private helperService: HelperService) { }

  user;
  canActivate(): boolean {
    console.log('AuthGuard# canActivate called');
    this.user = this.helperService.GetUser();
    if (this.user !== undefined) {
      return true;
    } else {
      this.router.navigate(['home']);
      return false;
    }
  }

}
