<div class="soon">
  <div class="container">
    <div class="row">
      <div class="col-md-9 col-lg-8">
        <div class="soon-content text-center">
          <div class="soon-content-wrapper">
            <img src="assets/images/logo-icon.png" alt="Logo" class="soon-logo mx-auto">
            <h1 class="soon-title">Coming Soon</h1><!-- End .soon-title -->
            <hr class="mt-2 mb-3 mt-md-3">
            <p>We are currently working on an awesome new site. Stay tuned for more information.
              Subscribe to our newsletter to stay updated on our progress.</p>
            <div class="mt-3"></div>
            <form #f="ngForm" (ngSubmit)="subscribeNow(f)">
              <div *ngIf="done === false" class="input-group mb-5">
                <input name="email" type="email" [(ngModel)]="emailaddress" class="form-control bg-transparent"
                  placeholder="Enter your Email Address" required email>
                <div class="input-group-append">
                  <button [disabled]="f.invalid" class="btn btn-outline-primary-2" type="submit">
                    <span *ngIf="isloading === false">SUBSCRIBE</span>
                    <i *ngIf="isloading === false" class="icon-long-arrow-right"></i>
                    <img *ngIf="isloading === true" src="assets/loader-white.svg" width="20" height="20" alt="loading">
                  </button>
                </div>
              </div>
              <div *ngIf="done === true" class="mb-6 text-center">
                <p>Done!</p>
              </div>
            </form>

            <div class="social-icons justify-content-center mb-0">
              <a href="#" class="social-icon" target="_blank" title="Facebook"><i class="icon-facebook-f"></i></a>
              <a href="https://www.instagram.com/amelieonline/" class="social-icon" target="_blank" title="Instagram"><i
                  class="icon-instagram"></i></a>
              <a href="https://www.pinterest.com/amelieonlineofficial/" class="social-icon" target="_blank"
                title="Pinterest"><i class="icon-pinterest"></i></a>
            </div><!-- End .soial-icons -->
          </div><!-- End .soon-content-wrapper -->
        </div><!-- End .soon-content -->
      </div><!-- End .col-md-9 col-lg-8 -->
    </div><!-- End .row -->
  </div><!-- End .container -->
  <div class="soon-bg bg-image" style="background-image: url(assets/images/backgrounds/soon-bg-2.jpg)"></div>
  <!-- End .soon-bg bg-image -->
</div><!-- End .soon -->
