import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavibarComponent } from './navibar/navibar.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { LayoutComponent } from './layout/layout.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SocialLoginModule, AuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { ProductsComponent } from './products/products.component';
import { ComingsoonComponent } from './comingsoon/comingsoon.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ProductdetailsComponent } from './productdetails/productdetails.component';
// import { MatDialogModule } from '@angular/material/dialog';

// Importing Services
import { AuthGuardService } from './auth-guard.service';
import { HelperService } from './services/helpers.service';
import { NotificationService } from './services/notification.service';
import { WishlistComponent } from './wishlist/wishlist.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ProductCardComponent } from './products/product-card/product-card.component';
import { DialogboxComponent } from './common/dialogbox/dialogbox.component';
import { MessageService } from './services/message.service';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { OrderitemComponent } from './myaccount/orderitem/orderitem.component';

const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('940979349710-u9nlndvi361l51m19mersjqnvjnbhtm3.apps.googleusercontent.com')
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('719101838828098')
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    NavibarComponent,
    FooterComponent,
    HomeComponent,
    LayoutComponent,
    AboutUsComponent,
    ContactUsComponent,
    ProductsComponent,
    ComingsoonComponent,
    PagenotfoundComponent,
    ProductdetailsComponent,
    WishlistComponent,
    CartComponent,
    CheckoutComponent,
    ProductCardComponent,
    DialogboxComponent,
    MyaccountComponent,
    OrderitemComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SocialLoginModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: false,
      progressBar: false,
      progressAnimation: 'decreasing',
      closeButton: true
    })
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    AuthGuardService,
    HelperService,
    NotificationService,
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
