import { Component, OnInit } from '@angular/core';
import { HelperService } from '../services/helpers.service';
import { of } from 'rxjs';
import { MessageService } from '../services/message.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  constructor(private helperService: HelperService, private msgService: MessageService, private notifyService: NotificationService) { }
  cartitems;
  localitems;
  totalamount = 0;
  cartloading = false;
  subscription;

  ngOnInit(): void {
    debugger
    this.getCartItems();

    this.subscription = this.msgService.getMessage().subscribe(message => {
      if (message.title === 'local_cart_updated') {
        this.updateTotals();
      }
    });


  }

  getCartItems() {
    this.cartitems = [];
    this.cartitems = this.helperService.GetLocalCartItems();
    this.updateTotals();
    // this.cartloading = true;
    // this.localitems = this.helperService.GetLocalCartItems();
    // const items = [];
    // if (this.localitems !== undefined) {
    //   this.localitems.forEach((item, index) => {
    //     if (!items.includes(item.prod_id)) {
    //       items.push(item.prod_id);
    //     }
    //   });
    // }
    // if (items.length > 0) {
    //   this.helperService.GetProductDetails(items).subscribe(
    //     (res) => {
    //       debugger
    //       this.cartitems = [];
    //       this.totalamount = 0;
    //       this.localitems.forEach((tempitem, index) => {
    //         var temp = res['Data'].filter(function (item) {
    //           return item.prod_id === tempitem.prod_id;
    //         });
    //         let product = JSON.parse(JSON.stringify(temp))
    //         product[0].qty = tempitem.qty;
    //         product[0].c_id = tempitem.c_id;
    //         this.cartitems.push(product[0]);
    //       });
    //       this.updateTotals();
    //       this.cartloading = false;
    //     },
    //     (err) => {
    //       console.log(err);
    //       this.cartloading = false;
    //     }
    //   );
    // } else {
    //   this.cartloading = false;
    // }
  }

  updateTotals() {
    this.totalamount = 0;
    this.cartitems.forEach((item, index) => {
      this.totalamount += item.prod_pricewotax * item.qty;
    });

  }

  removeCartItem(product, index) {
    this.cartloading = true;
    this.helperService.RemoveFromCart(product.c_id, product.qty).subscribe(
      (res) => {
        this.msgService.sendMessage('cart_updated', '');
        this.msgService.sendMessage('decrease_cart', '');
        this.notifyService.showSuccess('Item removed from cart!', '');
        this.updateTotals();
        this.cartloading = false;
      },
      (err) => {
        console.log(err);
        this.notifyService.showError('Error when removing item to cart', '');
        this.cartloading = false;
      }
    );
    // remove item from array
    debugger
    this.cartitems.splice(index, 1);
    this.updateTotals();
  }

  qtyChange(e) {
    console.log('Something happened: ', e);
    this.msgService.sendMessage('cart_qty_updated', this.cartitems);

  }

}
