import { Component, OnInit } from '@angular/core';
import { HelperService } from '../services/helpers.service';
import { NgForm } from '@angular/forms';
import { NotificationService } from '../services/notification.service';
import { Router } from '@angular/router';
import { MessageService } from '../services/message.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  constructor(
    private helperService: HelperService,
    private notifyService: NotificationService,
    private router: Router,
    private msgService: MessageService
  ) { }

  public user;
  cartitems = [];
  totalamount = 0;
  isloading;

  ngOnInit(): void {
    this.isloading = false;
    this.getUserProfile();

    // try to detect user location
    // debugger
    // if ('geolocation' in navigator) {
    //   navigator.geolocation.getCurrentPosition((position) => {
    //     console.log(position);
    //   });
    // } else {
    //   console.log('Location not available');
    // }
  }

  getUserProfile() {
    this.user = this.helperService.GetUser();
    this.user.country = 'Sri Lanka';

    this.cartitems = [];
    this.cartitems = this.helperService.GetLocalCartItems();
    this.updateTotals();
  }

  updateTotals() {
    this.totalamount = 0;
    this.cartitems.forEach((item, index) => {
      this.totalamount += item.prod_pricewotax * item.qty;
    });

  }

  proceedCheckout(form: NgForm) {
    this.isloading = true;
    // this.updateUser();
    // this.placeOrder();
  }

  updateUser() {
    this.helperService.SetUser(this.user);
    this.helperService.RegisterCustomer(this.user).subscribe(
      (res) => {
        this.user = res["Data"];
        this.helperService.SetUser(this.user);
        localStorage.setItem('amelieonline-current-user', JSON.stringify(this.user));
      },
      (err) => {
        console.log(err);
      }
    );
  }

  placeOrder() {
    const payload = {
      order_paymenttype: 'VISA',
      order_paymentreference: 'paymentreference',
      order_status: 'stage01',
      order_address: this.user,
      order_items: this.cartitems,
      order_paymentbreakdown: {},
    }
    this.helperService.AddNewOrder(payload).subscribe(
      (res) => {
        this.msgService.sendMessage('reset_cart', '');
        this.notifyService.showSuccess('Order was placed successfully.', '');
        this.router.navigate(['myaccount']);
        this.isloading = false;
      },
      (err) => {
        console.log(err);
        this.notifyService.showError('Failed to create order', '');
        this.isloading = false;
      }
    );
  }
}
