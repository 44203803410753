<main class="main">
  <div class="page-header text-center" style="background-image: url('assets/images/page-header-bg.jpg')">
    <div class="container">
      <h1 class="page-title">My Shopping Cart<span>at Amélie Online</span></h1>
    </div><!-- End .container -->
  </div><!-- End .page-header -->
  <nav aria-label="breadcrumb" class="breadcrumb-nav">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
        <li class="breadcrumb-item"><a href="#">Shop</a></li>
        <li class="breadcrumb-item active" aria-current="page">Shopping Cart</li>
      </ol>
    </div><!-- End .container -->
  </nav><!-- End .breadcrumb-nav -->

  <div class="page-content">
    <div class="cart">
      <div class="container">
        <div class="row">
          <div class="col-lg-9">

            <div *ngIf="cartloading === true">

              <div class="mt-10"></div>

              <div class="row">
                <img class="center" src="/assets/loader-primary.svg" alt="loading" width="60" height="60">
              </div>
              <!-- <div class="container">

              </div> -->

              <div class="mt-10"></div>

            </div>

            <div *ngIf="cartloading === false && cartitems.length === 0">
              <div class="row">
                <div class="mt-5"></div>
                <span class="center">Your Cart is empty!</span>
                <div class="mt-5"></div>
              </div>
              <div class="row">
                <div class="col-5 center">
                  <a href="javascript:void(0)" [routerLink]="['/home']"
                    class="btn btn-outline-dark-2 btn-block mb-3 center"><i class="icon-arrow-left"></i><span>Let's
                      shop!</span></a>
                </div>
              </div>
            </div>

            <table *ngIf="cartloading === false && cartitems.length > 0" class="table table-cart table-mobile">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Size</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Total</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of cartitems; let i=index;">
                  <td class="product-col">
                    <div class="product">
                      <figure class="product-media">
                        <a href="javascript:void(0)"
                          [routerLink]="['/products', item.prod_type.toLowerCase(), item.prod_code]">
                          <img style="object-fit: cover;height: 60px; width: 60px;" [src]="item.prod_images[0]"
                            alt="Product image">
                        </a>
                      </figure>

                      <h3 class="product-title">
                        <a href="javascript:void(0)"
                          [routerLink]="['/products', item.prod_type.toLowerCase(), item.prod_code]">{{item.prod_name}}</a>
                      </h3><!-- End .product-title -->
                    </div><!-- End .product -->
                  </td>
                  <td class="price-col">UK 12</td>
                  <td class="price-col">LKR {{item.prod_pricewotax}}</td>
                  <td class="quantity-col">
                    <div class="cart-product-quantity">
                      <input type="number" class="form-control" (change)="qtyChange($event)" [(ngModel)]="item.qty"
                        min="1" max="10" step="1" data-decimals="0" required>
                    </div><!-- End .cart-product-quantity -->
                  </td>
                  <td class="total-col">LKR {{item.prod_pricewotax * item.qty}}</td>
                  <td class="remove-col"><button class="btn-remove" (click)="removeCartItem(item, i)"><i
                        class="icon-close"></i></button></td>
                </tr>

              </tbody>
            </table><!-- End .table table-wishlist -->

            <div class="cart-bottom">
              <div class="cart-discount">
                <form action="#">
                  <div class="input-group">
                    <input type="text" class="form-control" required placeholder="coupon code">
                    <div class="input-group-append">
                      <button class="btn btn-outline-primary-2" type="submit"><i
                          class="icon-long-arrow-right"></i></button>
                    </div>
                  </div>
                </form>
              </div>

              <!-- <a href="javascript:void(0)" (click)="updateTotals()" class="btn btn-outline-dark-2"><span>UPDATE
                  CART</span><i class="icon-refresh"></i></a> -->
            </div>

          </div><!-- End .col-lg-9 -->
          <aside class="col-lg-3">
            <div class="summary summary-cart">
              <h3 class="summary-title">Cart Total</h3><!-- End .summary-title -->

              <table class="table table-summary">
                <tbody>
                  <tr class="summary-subtotal">
                    <td>Subtotal:</td>
                    <td>LKR {{totalamount}}</td>
                  </tr><!-- End .summary-subtotal -->

                  <!--
                  <tr class="summary-shipping">
                    <td>Shipping:</td>
                    <td>&nbsp;</td>
                  </tr>

                  <tr class="summary-shipping-row">
                    <td>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="free-shipping" name="shipping" class="custom-control-input">
                        <label class="custom-control-label" for="free-shipping">Free Shipping</label>
                      </div>
                    </td>
                    <td>LKR 0.00</td>
                  </tr>

                  <tr class="summary-shipping-row">
                    <td>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="standart-shipping" name="shipping" class="custom-control-input">
                        <label class="custom-control-label" for="standart-shipping">Standart:</label>
                      </div>
                    </td>
                    <td>LKR 10.00</td>
                  </tr>

                  <tr class="summary-shipping-row">
                    <td>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="express-shipping" name="shipping" class="custom-control-input">
                        <label class="custom-control-label" for="express-shipping">Express:</label>
                      </div>
                    </td>
                    <td>LKR 20.00</td>
                  </tr>

                  <tr class="summary-shipping-estimate">
                    <td>Estimate for Your Country<br> <a href="dashboard.html">Change address</a></td>
                    <td>&nbsp;</td>
                  </tr> -->

                  <tr class="summary-total">
                    <td>Total:</td>
                    <td>LKR {{totalamount}}</td>
                  </tr><!-- End .summary-total -->
                </tbody>
              </table><!-- End .table table-summary -->

              <a href="javascript:void(0)" [routerLink]="['/checkout']"
                class="btn btn-outline-primary-2 btn-order btn-block">PROCEED TO CHECKOUT</a>
            </div><!-- End .summary -->


          </aside><!-- End .col-lg-3 -->
        </div><!-- End .row -->
      </div><!-- End .container -->
    </div><!-- End .cart -->
  </div><!-- End .page-content -->
</main><!-- End .main -->
