import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from 'src/app/models/product.model';
import { HelperService } from 'src/app/services/helpers.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MessageService } from 'src/app/services/message.service';
declare var $: any;

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.css']
})
export class ProductCardComponent implements OnInit {

  @Input() productCategory: string;
  @Input() product: Product;

  displayCategory = '';
  user;
  isloading = false;
  displayImage = '';
  hoverImage = '';

  constructor(
    private router: Router,
    private helperService: HelperService,
    private notifyService: NotificationService,
    private msgService: MessageService
  ) { }

  ngOnInit(): void {
    this.setDisplayCategory();
    this.user = this.helperService.GetUser();
    this.displayImage = this.helperService.GetimageUrlforSize(this.product.prod_images[0], '500x500');
    if (this.product.prod_images.length > 1) {
      this.hoverImage = this.helperService.GetimageUrlforSize(this.product.prod_images[1], '500x500');
    } else {
      this.hoverImage = this.helperService.GetimageUrlforSize(this.product.prod_images[0], '500x500');
    }

  }

  setDisplayCategory() {
    switch (this.productCategory) {
      case 'dresses': this.displayCategory = 'Dress'; break;
      case 'tops': this.displayCategory = 'Top'; break;
      case 'pants': this.displayCategory = 'Pant'; break;
      case 'skirts': this.displayCategory = 'Skirt'; break;
      case 'jumpsuites': this.displayCategory = 'Jump Suite'; break;
    }
  }

  routeToProductDetails() {
    this.router.navigate(['products', this.productCategory, this.product.prod_code]);
  }

  checkwishlist() {
    this.isloading = true;
    this.user = this.helperService.GetUser();
    if (this.user !== undefined) {
      // continue to redirect
      this.addtoWishlist();
    } else {
      // display login modal
      this.openLoginPanel();
    }
  }

  addToCartValidate() {
    this.isloading = true;
    this.user = this.helperService.GetUser();
    if (this.user !== undefined) {
      // continue to redirect
      this.addtocart();
    } else {
      // display login modal
      this.openLoginPanel();
    }
  }

  openLoginPanel() {
    $('#loginModal').modal('show');
    this.isloading = false;
  }

  addtocart() {
    this.msgService.sendMessage('set_cart_loading', '');
    this.isloading = true;
    this.product.qty = 1;
    this.helperService.AddtoCart(this.product.prod_id, 1).subscribe(
      (res) => {
        this.msgService.sendMessage('add_to_cart', this.product);
        this.msgService.sendMessage('increment_cart', '');
        this.notifyService.showSuccess('Item added to cart!', '');
        this.isloading = false;
      },
      (err) => {
        console.log(err);
        this.msgService.sendMessage('stop_cart_loading', '');
        this.notifyService.showError('Error when adding item to cart', '');
        this.isloading = false;
      }
    );
  }

  addtoWishlist() {
    this.isloading = true;
    this.helperService.AddtoWishlist(this.product.prod_id).subscribe(
      (res) => {
        if (res['Message'] !== 'Wish already saved.') {
          this.msgService.sendMessage('increment_wishlsit', '');
        }
        this.notifyService.showSuccess('Item added to wishlist!', '');
        this.isloading = false;
      },
      (err) => {
        console.log(err);
        this.notifyService.showError('Error when adding item to wishlist', '');
        this.isloading = false;
      }
    );
  }
}
