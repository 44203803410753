<main class="main">


  <div class="page-header text-center" style="background-image: url('assets/images/page-header-bg.jpg')">
    <div class="container">
      <h1 class="page-title">My Wishlist<span>at Amélie Online</span></h1>
    </div>
  </div>



  <nav aria-label="breadcrumb" class="breadcrumb-nav">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/home']">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Wishlist</li>
      </ol>
    </div><!-- End .container -->
  </nav><!-- End .breadcrumb-nav -->

  <div class="page-content">

    <div *ngIf="isloading === true">

      <div class="mt-10"></div>

      <div class="container">
        <div class="row">
          <img class="center" src="/assets/loader-primary.svg" alt="loading" width="60" height="60">
        </div>
      </div>

      <div class="mt-10"></div>

    </div>

    <div *ngIf="isloading === false && products.length == 0" class="container">
      <div class="row">
        <span class="center">Your Wishlist is empty!</span>
      </div>
    </div>


    <div *ngIf="isloading === false && products.length > 0" class="container">
      <table class="table table-wishlist table-mobile">
        <thead>
          <tr>
            <th>Products</th>
            <th>Size</th>
            <th>Price</th>
            <th>Stock Status</th>
            <th></th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let item of products; let i=index;">
            <td class="product-col">
              <div class="product">
                <figure class="product-media">
                  <a href="javascript:void(0)"
                    [routerLink]="['/products', item.prod_type.toLowerCase(), item.prod_code]">
                    <img style="object-fit: cover;height: 60px; width: 60px;" [src]="item.prod_images[0]"
                      alt="Product image">
                  </a>
                </figure>

                <h3 class="product-title">
                  <a href="javascript:void(0)"
                    [routerLink]="['/products', item.prod_type.toLowerCase(), item.prod_code]">{{item.prod_name}}</a>
                </h3><!-- End .product-title -->
              </div><!-- End .product -->
            </td>
            <td class="price-col">UK 12</td>
            <td class="price-col">LKR {{item.prod_pricewotax}}</td>
            <td class="stock-col"><span class="in-stock">In stock</span><span class="out-of-stock">Out of stock</span>
            </td>
            <td class="action-col">
              <button class="btn btn-block btn-outline-primary-2"><i class="icon-cart-plus"></i>Add to Cart</button>
            </td>
            <td class="remove-col"><button (click)="removeFromWishlist(item.prod_id, i)" class="btn-remove"><i
                  class="icon-close"></i></button></td>
          </tr>
          <!-- <tr>
            <td class="product-col">
              <div class="product">
                <figure class="product-media">
                  <a href="#">
                    <img src="assets/images/products/table/product-2.jpg" alt="Product image">
                  </a>
                </figure>

                <h3 class="product-title">
                  <a href="#">Blue utility pinafore denim dress</a>
                </h3>
              </div>
            </td>
            <td class="price-col">LKR 76.00</td>
            <td class="stock-col"><span class="in-stock">In stock</span></td>
            <td class="action-col">
              <button class="btn btn-block btn-outline-primary-2"><i class="icon-cart-plus"></i>Add to Cart</button>
            </td>
            <td class="remove-col"><button class="btn-remove"><i class="icon-close"></i></button></td>
          </tr> -->


          <!-- <tr>
            <td class="product-col">
              <div class="product">
                <figure class="product-media">
                  <a href="#">
                    <img src="assets/images/products/table/product-3.jpg" alt="Product image">
                  </a>
                </figure>

                <h3 class="product-title">
                  <a href="#">Orange saddle lock front chain cross body bag</a>
                </h3>
              </div>
            </td>
            <td class="price-col">LKR 52.00</td>
            <td class="stock-col"><span class="out-of-stock">Out of stock</span></td>
            <td class="action-col">
              <button class="btn btn-block btn-outline-primary-2 disabled">Out of Stock</button>
            </td>
            <td class="remove-col"><button class="btn-remove"><i class="icon-close"></i></button></td>
          </tr> -->


        </tbody>
      </table><!-- End .table table-wishlist -->
    </div><!-- End .container -->
  </div><!-- End .page-content -->
</main><!-- End .main -->


<div id="confirmationModal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Want to continue?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <div class="row" style="margin: 20px;">
          <p>Are you sure you want to remove the item from your wishlist?</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="continueRemoval()">Yes, Remove</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
      </div>
    </div>
  </div>
</div>
