import { Component, OnInit } from '@angular/core';
import { HelperService } from '../services/helpers.service';
import { NgForm } from '@angular/forms';
import { NotificationService } from '../services/notification.service';
import { Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-myaccount',
  templateUrl: './myaccount.component.html',
  styleUrls: ['./myaccount.component.css']
})
export class MyaccountComponent implements OnInit {

  constructor(
    private helperService: HelperService,
    private notifyService: NotificationService,
    private router: Router
  ) { }

  private fragment: string;
  user;
  isloading = false;
  deliveryedit = false;
  activeorders;
  pastorders;

  ngOnInit(): void {
    debugger
    this.router.events.subscribe(s => {
      if (s instanceof NavigationEnd) {
        const tree = this.router.parseUrl(this.router.url);
        if (tree.fragment) {
          const element = document.querySelector('#' + tree.fragment);
          if (element) { element.scrollIntoView(true); }
        }
      }
    });

    this.user = this.helperService.GetUser();
    this.deliveryedit = false;
    this.getOrders();
  }

  updateUser(f) {
    if (f.valid) {
      this.isloading = true;
      this.helperService.SetUser(this.user);
      this.helperService.RegisterCustomer(this.user).subscribe(
        (res) => {
          this.user = res["Data"];
          this.helperService.SetUser(this.user);
          localStorage.setItem('amelieonline-current-user', JSON.stringify(this.user));
          this.notifyService.showSuccess('User details updated!', '');
          this.isloading = false;
          this.deliveryedit = false;
        },
        (err) => {
          this.isloading = false;
          this.deliveryedit = false;
          console.log(err);
        }
      );
    } else {
      this.isloading = false;
      this.deliveryedit = false;
      this.notifyService.showWarning('Please complete all the fields.', '');
    }

  }

  getOrders() {
    this.activeorders = [];
    this.pastorders = [];
    this.helperService.GetOrders().subscribe(
      (res) => {
        res["Data"].forEach(element => {
          if (element.order_status !== 'stage04') {
            this.activeorders.push(element);
          } else {
            this.pastorders.push(element);
          }
        });
        if (this.activeorders.lenght > 0) {
          // tslint:disable-next-line: max-line-length
          this.notifyService.showSuccess('You have ' + (this.activeorders.lenght) + ' active order' + (this.activeorders.lenght === 1 ? '' : 's') + '!', '');
        }

      },
      (err) => {
        this.notifyService.showError('Failed to get order details', '');
        console.log(err);
      }
    );
  }
}
