<div class="card card-dashboard">

  <div class="row" style="margin: 15px 15px 0px 15px">
    <div class="col-sm-3"> <b>Order Date</b><br>{{orderDisplayDateStage01}}</div>
    <div class="col-sm-3"> <b>Order Number</b><br>{{order.order_number}}</div>
    <div class="col-sm-3"> <b>Status</b><br> <span class="label label-default">{{orderProgress}}</span></div>
    <div class="col-sm-3">
      <b>Payment Type</b><br> <span class="label label-default">{{order.order_paymenttype}}</span>
      <!-- <button class="btn btn-primary float-right">More Details<i class="icon-arrow-down"></i></button> -->
    </div>
  </div>
  <hr class="mb-1 mt-1">
  <div class="row">
    <div class="col-sm-3" style="text-align: center;"><img class="center" style="width: 30px; height: 30px;"
        [src]="stageone" alt="">
      {{stageonetext}}</div>
    <div class="col-sm-3" style="text-align: center;"><img class="center" style="width: 30px; height: 30px;"
        [src]="stagetwo" alt="">
      {{stagetwotext}}</div>
    <div class="col-sm-3" style="text-align: center;"><img class="center" style="width: 30px; height: 30px;"
        [src]="stagethree" alt="">
      {{ stagethreetext}}</div>
    <div class="col-sm-3" style="text-align: center;"><img class="center" style="width: 30px; height: 30px;"
        [src]="stagefour" alt="">
      {{ stagefourtext}}</div>
  </div>
  <div class="row">
    <div style="width: 100%;" class="progress">
      <div class="progress-bar active" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"
        [ngStyle]="{'width': progress}">
      </div>
    </div>
  </div>
  <hr class="mb-0 mt-1">
  <div class="row" style="margin: 15px;">
    <div class="col-sm-12">
      <button *ngIf="moredetails === false" (click)="moredetails = true;"
        class="btn btn-outline-primary-2 float-right">Show
        More<i class="icon-arrow-down"></i></button>
      <button *ngIf="moredetails === true" (click)="moredetails = false;"
        class="btn btn-outline-primary-2 float-right">Show
        Less<i class="icon-arrow-up"></i></button>
    </div>

  </div>
  <div *ngIf="moredetails === true" class="row" style="margin: 0px 15px 15px 15px">
    <table class="table table-cart table-mobile">
      <thead>
        <tr>
          <th style="text-align: center;">Product</th>
          <th style="text-align: center;">Size</th>
          <th style="text-align: center;">Price</th>
          <th style="text-align: center;">Quantity</th>
          <th style="text-align: center;">Total</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of order.order_items; let i=index;">
          <td class="product-col">
            <div class="product">
              <figure class="product-media">
                <a href="javascript:void(0)" [routerLink]="['/products', item.prod_type.toLowerCase(), item.prod_code]">
                  <img style="object-fit: cover;height: 60px; width: 60px;" [src]="item.prod_images[0]"
                    alt="Product image">
                </a>
              </figure>

              <h3 class="product-title">
                <a href="javascript:void(0)"
                  [routerLink]="['/products', item.prod_type.toLowerCase(), item.prod_code]">{{item.prod_name}}</a>
              </h3>
            </div>
          </td>
          <td class="quantity-col" style="text-align: center;">UK 12</td>
          <td class="price-col" style="text-align: center;">LKR {{item.prod_pricewotax}}</td>
          <td class="quantity-col" style="text-align: center;">{{item.qty}}</td>

          <td class="total-col" style="text-align: center;">LKR
            {{item.prod_pricewotax * item.qty}}</td>
        </tr>

      </tbody>
    </table>


  </div>

  <button *ngIf="moredetails === true" (click)="next()">Next</button>
  <button *ngIf="moredetails === true" (click)="back()">Back</button>



</div>
