import { Component, OnInit } from '@angular/core';
import { HelperService } from '../services/helpers.service';
import { NotificationService } from '../services/notification.service';
import { MessageService } from '../services/message.service';
declare var $: any;

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.css']
})
export class WishlistComponent implements OnInit {

  constructor(
    private helperService: HelperService,
    private notifyService: NotificationService,
    private msgService: MessageService
  ) { }

  products = [];
  isloading = false;
  removingProduct;
  removingIndex;

  ngOnInit(): void {
    this.getwishlistItems();
  }

  getwishlistItems() {
    this.isloading = true;
    this.helperService.GetWishlist().subscribe(
      (res) => {
        const items = [];
        res['Data'].forEach((item, index) => {
          items.push(item.prod_id);
        });
        this.helperService.GetProductDetails(items).subscribe(
          (res) => {
            res['Data'].forEach(prod => {
              prod.prod_images[0] = this.helperService.GetimageUrlforSize(prod.prod_images[0], '250x250');
            });

            this.products = res['Data'];
            this.isloading = false;
            // this.notifyService.showSuccess('Email added to our list!', '');
            // this.isloading = false;
            // this.done = true;
          },
          (err) => {
            console.log(err);
            this.isloading = false;
            // this.notifyService.showError('Data shown successfully !!', 'ItSolutionStuff.com');
            // this.isloading = false;
          }
        );
      },
      (err) => {
        console.log(err);
        this.isloading = false;
        // this.notifyService.showError('Data shown successfully !!', 'ItSolutionStuff.com');
        // this.isloading = false;
      }
    );
  }

  removeFromWishlist(prod_id, index) {
    this.removingIndex = index;
    this.removingProduct = prod_id;
    this.openConfirmationPanel();
  }

  openConfirmationPanel() {
    $('#confirmationModal').modal('show');
  }

  continueRemoval() {
    $('#confirmationModal').modal('hide');
    this.isloading = true;
    this.helperService.RemoveFromWishlist(this.removingProduct).subscribe(
      (res) => {
        this.isloading = false;
        this.products.splice(this.removingIndex, 1);
        this.notifyService.showSuccess('Item removed from Wishlist', '');
        this.msgService.sendMessage('decrease_wishlsit', '');
      },
      (err) => {
        console.log(err);
        this.isloading = false;
        // this.notifyService.showError('Data shown successfully !!', 'ItSolutionStuff.com');
        // this.isloading = false;
      }
    );
  }

}
