<main class="main">

  <div class="intro-slider-container">
    <div class="intro-slider owl-carousel owl-theme owl-nav-inside owl-light" data-toggle="owl" data-owl-options='{
            "dots": false,
            "nav": false,
            "responsive": {
                "992": {
                    "nav": true
                }
            }
        }'>
      <div class="intro-slide" style="background-image: url(assets/images/demos/demo-6/slider/slide-1.jpg);">
        <div class="container intro-content text-center">
          <h3 class="intro-subtitle text-white">You're Looking Good</h3><!-- End .h3 intro-subtitle -->
          <h1 class="intro-title text-white">New Lookbook</h1><!-- End .intro-title -->

          <a href="category.html" class="btn btn-outline-white-4">
            <span>Discover More</span>
          </a>
        </div><!-- End .intro-content -->
      </div><!-- End .intro-slide -->

      <div class="intro-slide" style="background-image: url(assets/images/demos/demo-6/slider/slide-2.jpg);">
        <div class="container intro-content text-center">
          <h3 class="intro-subtitle text-white">Don’t Miss</h3><!-- End .h3 intro-subtitle -->
          <h1 class="intro-title text-white">Mysrety Deals</h1><!-- End .intro-title -->

          <a href="category.html" class="btn btn-outline-white-4">
            <span>Discover More</span>
          </a>
        </div><!-- End .intro-content -->
      </div><!-- End .intro-slide -->
    </div><!-- End .intro-slider owl-carousel owl-theme -->

    <span class="slider-loader"></span><!-- End .slider-loader -->
  </div><!-- End .intro-slider-container -->


  <!-- <hr class="mb-4"> -->

  <div class="container">

    <hr class="mb-4">

    <h2 class="title text-center mb-3">We've Got Fashion For Everyone</h2>

    <div class="row ">
      <div class="col-lg-8">
        <div class="row">
          <div class="col-sm-8">
            <div class="banner banner-cat banner-badge">
              <a [routerLink]="['/products']">
                <img src="assets/images/category/fullwidth-page/banner-1.jpg" alt="Banner">
              </a>

              <a class="banner-link" [routerLink]="['/products']">
                <h3 class="banner-title">Jackets</h3><!-- End .banner-title -->
                <h4 class="banner-subtitle">2 Products</h4><!-- End .banner-subtitle -->
                <span class="banner-link-text">Shop Now</span>
              </a><!-- End .banner-link -->
            </div><!-- End .banner -->
          </div><!-- End .col-sm-8 -->

          <div class="col-sm-4">
            <div class="banner banner-cat banner-badge">
              <a [routerLink]="['/products']">
                <img src="assets/images/category/fullwidth-page/banner-2.jpg" alt="Banner">
              </a>

              <a class="banner-link" [routerLink]="['/products']">
                <h3 class="banner-title">Jeans</h3>
                <h4 class="banner-subtitle">1 Product</h4>
                <span class="banner-link-text">Shop Now</span>
              </a>
            </div>
          </div>

          <div class="col-sm-4">
            <div class="banner banner-cat banner-badge">
              <a [routerLink]="['/products']">
                <img src="assets/images/category/fullwidth-page/banner-3.jpg" alt="Banner">
              </a>

              <a class="banner-link" [routerLink]="['/products']">
                <h3 class="banner-title">Sportwear</h3><!-- End .banner-title -->
                <h4 class="banner-subtitle">0 Product</h4><!-- End .banner-subtitle -->
                <span class="banner-link-text">Shop Now</span>
              </a><!-- End .banner-link -->
            </div><!-- End .banner -->
          </div><!-- End .col-sm-4 -->

          <div class="col-sm-8">
            <div class="banner banner-cat banner-badge">
              <a [routerLink]="['/products']">
                <img src="assets/images/category/fullwidth-page/banner-4.jpg" alt="Banner">
              </a>

              <a class="banner-link" [routerLink]="['/products']">
                <h3 class="banner-title">Bags</h3><!-- End .banner-title -->
                <h4 class="banner-subtitle">4 Products</h4><!-- End .banner-subtitle -->
                <span class="banner-link-text">Shop Now</span>
              </a><!-- End .banner-link -->
            </div><!-- End .banner -->
          </div><!-- End .col-sm-8 -->
        </div><!-- End .row -->
      </div><!-- End .col-lg-6 -->

      <div class="col-lg-4">
        <div class="row">

          <div class="col-sm-12">
            <div class="banner banner-cat banner-badge">
              <a [routerLink]="['/products']">
                <img src="assets/images/category/fullwidth-page/banner-8.jpg" alt="Banner">
              </a>

              <a class="banner-link" [routerLink]="['/products']">
                <h3 class="banner-title">Jumpers</h3>
                <h4 class="banner-subtitle">1 Product</h4>
                <span class="banner-link-text">Shop Now</span>
              </a>
            </div>
          </div><!-- End .col-sm-8 -->

          <!-- <div class="col-sm-12">
            <div class="banner banner-cat banner-badge">
              <a href="#">
                <img src="assets/images/category/fullwidth-page/banner-5.jpg" alt="Banner">
              </a>

              <a class="banner-link" href="#">
                <h3 class="banner-title">Dresses</h3>
                <h4 class="banner-subtitle">3 Products</h4>
                <span class="banner-link-text">Shop Now</span>
              </a>
            </div>

            <div class="banner banner-cat banner-badge">
              <a href="#">
                <img src="assets/images/category/fullwidth-page/banner-6.jpg" alt="Banner">
              </a>

              <a class="banner-link" href="#">
                <h3 class="banner-title">Shoes</h3>
                <h4 class="banner-subtitle">2 Products</h4>
                <span class="banner-link-text">Shop Now</span>
              </a>
            </div>
          </div>

          <div class="col-sm-4">
            <div class="banner banner-cat banner-badge">
              <a href="#">
                <img src="assets/images/category/fullwidth-page/banner-7.jpg" alt="Banner">
              </a>

              <a class="banner-link" href="#">
                <h3 class="banner-title">T-shirts</h3>
                <h4 class="banner-subtitle">0 Products</h4>
                <span class="banner-link-text">Shop Now</span>
              </a>
            </div>
          </div> -->


        </div>
      </div>


    </div>
  </div>

  <div class="mb-3"></div><!-- End .mb-6 -->

  <div class="bg-lighter pt-6 pb-9">
    <div class="container">
      <ul class="nav nav-pills nav-big nav-border-anim justify-content-center mb-2 mb-md-3" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="products-featured-link" data-toggle="tab" href="#products-featured-tab"
            role="tab" aria-controls="products-featured-tab" aria-selected="true">Featured</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="products-sale-link" data-toggle="tab" href="#products-sale-tab" role="tab"
            aria-controls="products-sale-tab" aria-selected="false">On Sale</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="products-top-link" data-toggle="tab" href="#products-top-tab" role="tab"
            aria-controls="products-top-tab" aria-selected="false">Top Rated</a>
        </li>
      </ul>

      <div class="tab-content tab-content-carousel">
        <div class="tab-pane p-0 fade show active" id="products-featured-tab" role="tabpanel"
          aria-labelledby="products-featured-link">
          <div class="owl-carousel owl-simple carousel-equal-height carousel-with-shadow" data-toggle="owl"
            data-owl-options='{
                                  "nav": false,
                                  "dots": true,
                                  "margin": 20,
                                  "loop": false,
                                  "responsive": {
                                      "0": {
                                          "items":2
                                      },
                                      "480": {
                                          "items":2
                                      },
                                      "768": {
                                          "items":3
                                      },
                                      "992": {
                                          "items":4
                                      },
                                      "1200": {
                                          "items":4,
                                          "nav": true,
                                          "dots": false
                                      }
                                  }
                              }'>
            <div class="product product-2">
              <figure class="product-media">
                <a href="product.html">
                  <img src="assets/images/demos/demo-8/products/product-1-1.jpg" alt="Product image"
                    class="product-image">
                  <img src="assets/images/demos/demo-8/products/product-1-2.jpg" alt="Product image"
                    class="product-image-hover">
                </a>

                <div class="product-action-vertical">
                  <a href="#" class="btn-product-icon btn-wishlist btn-expandable" title="Add to wishlist"><span>add to
                      wishlist</span></a>
                </div><!-- End .product-action -->

                <div class="product-action ">
                  <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                </div><!-- End .product-action -->
              </figure><!-- End .product-media -->

              <div class="product-body">
                <div class="product-cat">
                  <a href="#">Clothing</a>
                </div><!-- End .product-cat -->
                <h3 class="product-title"><a href="product.html">Denim jacket</a></h3><!-- End .product-title -->
                <div class="product-price">
                  $19.99
                </div><!-- End .product-price -->
                <div class="product-nav product-nav-thumbs">
                  <a href="#" class="active">
                    <img src="assets/images/demos/demo-8/products/product-1-thumb.jpg" alt="product desc">
                  </a>
                  <a href="#">
                    <img src="assets/images/demos/demo-8/products/product-1-2-thumb.jpg" alt="product desc">
                  </a>
                  <a href="#">
                    <img src="assets/images/demos/demo-8/products/product-1-3-thumb.jpg" alt="product desc">
                  </a>
                </div>

              </div><!-- End .product-body -->
            </div><!-- End .product -->

            <div class="product product-2">
              <figure class="product-media">
                <a href="product.html">
                  <img src="assets/images/demos/demo-8/products/product-2-1.jpg" alt="Product image"
                    class="product-image">
                  <img src="assets/images/demos/demo-8/products/product-2-2.jpg" alt="Product image"
                    class="product-image-hover">
                </a>

                <div class="product-action-vertical">
                  <a href="#" class="btn-product-icon btn-wishlist btn-expandable" title="Add to wishlist"><span>add to
                      wishlist</span></a>
                </div><!-- End .product-action -->

                <div class="product-action ">
                  <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                </div><!-- End .product-action -->
              </figure><!-- End .product-media -->

              <div class="product-body">
                <div class="product-cat">
                  <a href="#">Shoes</a>
                </div><!-- End .product-cat -->
                <h3 class="product-title"><a href="product.html">Sandals</a></h3><!-- End .product-title -->
                <div class="product-price">
                  $24.99
                </div><!-- End .product-price -->
              </div><!-- End .product-body -->
            </div><!-- End .product -->

            <div class="product product-2">
              <figure class="product-media">
                <span class="product-label label-sale">sale</span>
                <a href="product.html">
                  <img src="assets/images/demos/demo-8/products/product-3-1.jpg" alt="Product image"
                    class="product-image">
                  <img src="assets/images/demos/demo-8/products/product-3-2.jpg" alt="Product image"
                    class="product-image-hover">
                </a>

                <div class="product-action-vertical">
                  <a href="#" class="btn-product-icon btn-wishlist btn-expandable" title="Add to wishlist"><span>add to
                      wishlist</span></a>
                </div><!-- End .product-action -->

                <div class="product-action ">
                  <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                </div><!-- End .product-action -->
              </figure><!-- End .product-media -->

              <div class="product-body">
                <div class="product-cat">
                  <a href="#">Clothing</a>
                </div><!-- End .product-cat -->
                <h3 class="product-title"><a href="product.html">Printed sweatshirt</a></h3><!-- End .product-title -->
                <div class="product-price">
                  <span class="new-price">Now $7.99</span>
                  <span class="old-price">Was $12.99</span>
                </div><!-- End .product-price -->
              </div><!-- End .product-body -->
            </div><!-- End .product -->

            <div class="product product-2">
              <figure class="product-media">
                <a href="product.html">
                  <img src="assets/images/demos/demo-8/products/product-4-1.jpg" alt="Product image"
                    class="product-image">
                  <img src="assets/images/demos/demo-8/products/product-4-2.jpg" alt="Product image"
                    class="product-image-hover">
                </a>

                <div class="product-action-vertical">
                  <a href="#" class="btn-product-icon btn-wishlist btn-expandable" title="Add to wishlist"><span>add to
                      wishlist</span></a>
                </div><!-- End .product-action -->

                <div class="product-action ">
                  <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                </div><!-- End .product-action -->
              </figure><!-- End .product-media -->

              <div class="product-body">
                <div class="product-cat">
                  <a href="#">Clothing</a>
                </div><!-- End .product-cat -->
                <h3 class="product-title"><a href="product.html">Linen-blend paper bag trousers</a></h3>
                <!-- End .product-title -->
                <div class="product-price">
                  $17.99
                </div><!-- End .product-price -->
                <div class="product-nav product-nav-thumbs">
                  <a href="#" class="active">
                    <img src="assets/images/demos/demo-8/products/product-4-thumb.jpg" alt="product desc">
                  </a>
                  <a href="#">
                    <img src="assets/images/demos/demo-8/products/product-4-2-thumb.jpg" alt="product desc">
                  </a>
                </div>

              </div><!-- End .product-body -->
            </div><!-- End .product -->

          </div><!-- End .owl-carousel -->
        </div><!-- .End .tab-pane -->
        <div class="tab-pane p-0 fade" id="products-sale-tab" role="tabpanel" aria-labelledby="products-sale-link">
          <div class="owl-carousel owl-simple carousel-equal-height carousel-with-shadow" data-toggle="owl"
            data-owl-options='{
                                  "nav": false,
                                  "dots": true,
                                  "margin": 20,
                                  "loop": false,
                                  "responsive": {
                                      "0": {
                                          "items":1
                                      },
                                      "480": {
                                          "items":2
                                      },
                                      "768": {
                                          "items":3
                                      },
                                      "992": {
                                          "items":4
                                      },
                                      "1200": {
                                          "items":4,
                                          "nav": true,
                                          "dots": false
                                      }
                                  }
                              }'>
            <div class="product product-2">
              <figure class="product-media">
                <a href="product.html">
                  <img src="assets/images/demos/demo-8/products/product-2-1.jpg" alt="Product image"
                    class="product-image">
                  <img src="assets/images/demos/demo-8/products/product-2-2.jpg" alt="Product image"
                    class="product-image-hover">
                </a>

                <div class="product-action-vertical">
                  <a href="#" class="btn-product-icon btn-wishlist btn-expandable" title="Add to wishlist"><span>add to
                      wishlist</span></a>
                </div><!-- End .product-action -->

                <div class="product-action ">
                  <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                </div><!-- End .product-action -->
              </figure><!-- End .product-media -->

              <div class="product-body">
                <div class="product-cat">
                  <a href="#">Shoes</a>
                </div><!-- End .product-cat -->
                <h3 class="product-title"><a href="product.html">Sandals</a></h3><!-- End .product-title -->
                <div class="product-price">
                  $24.99
                </div><!-- End .product-price -->
              </div><!-- End .product-body -->
            </div><!-- End .product -->

            <div class="product product-2">
              <figure class="product-media">
                <span class="product-label label-sale">sale</span>
                <a href="product.html">
                  <img src="assets/images/demos/demo-8/products/product-3-1.jpg" alt="Product image"
                    class="product-image">
                  <img src="assets/images/demos/demo-8/products/product-3-2.jpg" alt="Product image"
                    class="product-image-hover">
                </a>

                <div class="product-action-vertical">
                  <a href="#" class="btn-product-icon btn-wishlist btn-expandable" title="Add to wishlist"><span>add to
                      wishlist</span></a>
                </div><!-- End .product-action -->

                <div class="product-action ">
                  <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                </div><!-- End .product-action -->
              </figure><!-- End .product-media -->

              <div class="product-body">
                <div class="product-cat">
                  <a href="#">Clothing</a>
                </div><!-- End .product-cat -->
                <h3 class="product-title"><a href="product.html">Printed sweatshirt</a></h3><!-- End .product-title -->
                <div class="product-price">
                  <span class="new-price">Now $7.99</span>
                  <span class="old-price">Was $12.99</span>
                </div><!-- End .product-price -->
              </div><!-- End .product-body -->
            </div><!-- End .product -->
          </div><!-- End .owl-carousel -->
        </div><!-- .End .tab-pane -->
        <div class="tab-pane p-0 fade" id="products-top-tab" role="tabpanel" aria-labelledby="products-top-link">
          <div class="owl-carousel owl-simple carousel-equal-height carousel-with-shadow" data-toggle="owl"
            data-owl-options='{
                                  "nav": false,
                                  "dots": true,
                                  "margin": 20,
                                  "loop": false,
                                  "responsive": {
                                      "0": {
                                          "items":1
                                      },
                                      "480": {
                                          "items":2
                                      },
                                      "768": {
                                          "items":3
                                      },
                                      "992": {
                                          "items":4
                                      },
                                      "1200": {
                                          "items":4,
                                          "nav": true,
                                          "dots": false
                                      }
                                  }
                              }'>

            <div class="product product-2">
              <figure class="product-media">
                <a href="product.html">
                  <img src="assets/images/demos/demo-8/products/product-2-1.jpg" alt="Product image"
                    class="product-image">
                  <img src="assets/images/demos/demo-8/products/product-2-2.jpg" alt="Product image"
                    class="product-image-hover">
                </a>

                <div class="product-action-vertical">
                  <a href="#" class="btn-product-icon btn-wishlist btn-expandable" title="Add to wishlist"><span>add to
                      wishlist</span></a>
                </div><!-- End .product-action -->

                <div class="product-action ">
                  <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                </div><!-- End .product-action -->
              </figure><!-- End .product-media -->

              <div class="product-body">
                <div class="product-cat">
                  <a href="#">Shoes</a>
                </div><!-- End .product-cat -->
                <h3 class="product-title"><a href="product.html">Sandals</a></h3><!-- End .product-title -->
                <div class="product-price">
                  $24.99
                </div><!-- End .product-price -->
              </div><!-- End .product-body -->
            </div><!-- End .product -->

            <div class="product product-2">
              <figure class="product-media">
                <span class="product-label label-sale">sale</span>
                <a href="product.html">
                  <img src="assets/images/demos/demo-8/products/product-3-1.jpg" alt="Product image"
                    class="product-image">
                  <img src="assets/images/demos/demo-8/products/product-3-2.jpg" alt="Product image"
                    class="product-image-hover">
                </a>

                <div class="product-action-vertical">
                  <a href="#" class="btn-product-icon btn-wishlist btn-expandable" title="Add to wishlist"><span>add to
                      wishlist</span></a>
                </div><!-- End .product-action -->

                <div class="product-action ">
                  <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                </div><!-- End .product-action -->
              </figure><!-- End .product-media -->

              <div class="product-body">
                <div class="product-cat">
                  <a href="#">Clothing</a>
                </div><!-- End .product-cat -->
                <h3 class="product-title"><a href="product.html">Printed sweatshirt</a></h3><!-- End .product-title -->
                <div class="product-price">
                  <span class="new-price">Now $7.99</span>
                  <span class="old-price">Was $12.99</span>
                </div><!-- End .product-price -->
              </div><!-- End .product-body -->
            </div><!-- End .product -->

            <div class="product product-2">
              <figure class="product-media">
                <a href="product.html">
                  <img src="assets/images/demos/demo-8/products/product-4-1.jpg" alt="Product image"
                    class="product-image">
                  <img src="assets/images/demos/demo-8/products/product-4-2.jpg" alt="Product image"
                    class="product-image-hover">
                </a>

                <div class="product-action-vertical">
                  <a href="#" class="btn-product-icon btn-wishlist btn-expandable" title="Add to wishlist"><span>add to
                      wishlist</span></a>
                </div><!-- End .product-action -->

                <div class="product-action ">
                  <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                </div><!-- End .product-action -->
              </figure><!-- End .product-media -->

              <div class="product-body">
                <div class="product-cat">
                  <a href="#">Clothing</a>
                </div><!-- End .product-cat -->
                <h3 class="product-title"><a href="product.html">Linen-blend paper bag trousers</a></h3>
                <!-- End .product-title -->
                <div class="product-price">
                  $17.99
                </div><!-- End .product-price -->
                <div class="product-nav product-nav-thumbs">
                  <a href="#" class="active">
                    <img src="assets/images/demos/demo-8/products/product-4-thumb.jpg" alt="product desc">
                  </a>
                  <a href="#">
                    <img src="assets/images/demos/demo-8/products/product-4-2-thumb.jpg" alt="product desc">
                  </a>
                </div>

              </div><!-- End .product-body -->
            </div><!-- End .product -->
          </div><!-- End .owl-carousel -->
        </div><!-- .End .tab-pane -->
      </div><!-- End .tab-content -->
    </div><!-- End .container -->
  </div>


  <div class="mb-5"></div><!-- End .mb-5 -->

  <div class="container recent-arrivals">
    <div class="heading heading-flex align-items-center mb-3">
      <h2 class="title title-lg">Recent Arrivals</h2><!-- End .title -->
      <ul class="nav nav-pills nav-border-anim justify-content-center" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="recent-all-link" data-toggle="tab" href="#recent-all-tab" role="tab"
            aria-controls="recent-all-tab" aria-selected="true">All</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="recent-women-link" data-toggle="tab" href="#recent-women-tab" role="tab"
            aria-controls="recent-women-tab" aria-selected="false">Women</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="recent-men-link" data-toggle="tab" href="#recent-men-tab" role="tab"
            aria-controls="recent-men-tab" aria-selected="false">Men</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="recent-shoes-link" data-toggle="tab" href="#recent-shoes-tab" role="tab"
            aria-controls="recent-shoes-tab" aria-selected="false">Shoes & Boots</a>
        </li>
      </ul>
    </div><!-- End .heading -->

    <div class="tab-content">
      <div class="tab-pane p-0 fade show active" id="recent-all-tab" role="tabpanel" aria-labelledby="recent-all-link">
        <div class="products">
          <div class="row justify-content-center">
            <div class="col-6 col-md-4 col-lg-3">
              <div class="product product-2 text-center">
                <figure class="product-media">
                  <span class="product-label label-sale">Sale</span>
                  <a href="product.html">
                    <img src="assets/images/demos/demo-8/products/product-5-1.jpg" alt="Product image"
                      class="product-image">
                    <img src="assets/images/demos/demo-8/products/product-5-2.jpg" alt="Product image"
                      class="product-image-hover">
                  </a>

                  <div class="product-action-vertical">
                    <a href="#" class="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                  </div><!-- End .product-action-vertical -->

                  <div class="product-action">
                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                  </div><!-- End .product-action -->
                </figure><!-- End .product-media -->

                <div class="product-body">
                  <div class="product-cat">
                    <a href="#">Clothing</a>
                  </div><!-- End .product-cat -->
                  <h3 class="product-title"><a href="product.html">Tie-detail top</a></h3><!-- End .product-title -->
                  <div class="product-price">
                    <span class="new-price">Now $3.99</span>
                    <span class="old-price">Was $6.99</span>
                  </div><!-- End .product-price -->
                </div><!-- End .product-body -->
              </div><!-- End .product -->
            </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->

            <div class="col-6 col-md-4 col-lg-3">
              <div class="product product-2 text-center">
                <figure class="product-media">
                  <a href="product.html">
                    <img src="assets/images/demos/demo-8/products/product-6-1.jpg" alt="Product image"
                      class="product-image">
                    <img src="assets/images/demos/demo-8/products/product-6-2.jpg" alt="Product image"
                      class="product-image-hover">
                  </a>

                  <div class="product-action-vertical">
                    <a href="#" class="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                  </div><!-- End .product-action-vertical -->

                  <div class="product-action">
                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                  </div><!-- End .product-action -->
                </figure><!-- End .product-media -->

                <div class="product-body">
                  <div class="product-cat">
                    <a href="#">Shoes</a>
                  </div><!-- End .product-cat -->
                  <h3 class="product-title"><a href="product.html">Sandals</a></h3><!-- End .product-title -->
                  <div class="product-price">
                    $12.99
                  </div><!-- End .product-price -->
                </div><!-- End .product-body -->
              </div><!-- End .product -->
            </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->

            <div class="col-6 col-md-4 col-lg-3">
              <div class="product product-2 text-center">
                <figure class="product-media">
                  <a href="product.html">
                    <img src="assets/images/demos/demo-8/products/product-7-1.jpg" alt="Product image"
                      class="product-image">
                    <img src="assets/images/demos/demo-8/products/product-7-2.jpg" alt="Product image"
                      class="product-image-hover">
                  </a>

                  <div class="product-action-vertical">
                    <a href="#" class="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                  </div><!-- End .product-action-vertical -->

                  <div class="product-action">
                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                  </div><!-- End .product-action -->
                </figure><!-- End .product-media -->

                <div class="product-body">
                  <div class="product-cat">
                    <a href="#">Bags</a>
                  </div><!-- End .product-cat -->
                  <h3 class="product-title"><a href="product.html">Small bucket bag</a></h3><!-- End .product-title -->
                  <div class="product-price">
                    $14.99
                  </div><!-- End .product-price -->

                  <div class="product-nav product-nav-thumbs">
                    <a href="#" class="active">
                      <img src="assets/images/demos/demo-8/products/product-7-thumb.jpg" alt="product desc">
                    </a>
                    <a href="#">
                      <img src="assets/images/demos/demo-8/products/product-7-2-thumb.jpg" alt="product desc">
                    </a>
                  </div><!-- End .product-nav -->
                </div><!-- End .product-body -->
              </div><!-- End .product -->
            </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->

            <div class="col-6 col-md-4 col-lg-3">
              <div class="product product-2 text-center">
                <figure class="product-media">
                  <a href="product.html">
                    <img src="assets/images/demos/demo-8/products/product-8-1.jpg" alt="Product image"
                      class="product-image">
                    <img src="assets/images/demos/demo-8/products/product-8-2.jpg" alt="Product image"
                      class="product-image-hover">
                  </a>

                  <div class="product-action-vertical">
                    <a href="#" class="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                  </div><!-- End .product-action-vertical -->

                  <div class="product-action">
                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                  </div><!-- End .product-action -->
                </figure><!-- End .product-media -->

                <div class="product-body">
                  <div class="product-cat">
                    <a href="#">Clothing</a>
                  </div><!-- End .product-cat -->
                  <h3 class="product-title"><a href="product.html">Denim jacket</a></h3><!-- End .product-title -->
                  <div class="product-price">
                    $34.99
                  </div><!-- End .product-price -->
                </div><!-- End .product-body -->
              </div><!-- End .product -->
            </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->

            <div class="col-6 col-md-4 col-lg-3">
              <div class="product product-2 text-center">
                <figure class="product-media">
                  <a href="product.html">
                    <img src="assets/images/demos/demo-8/products/product-9-1.jpg" alt="Product image"
                      class="product-image">
                    <img src="assets/images/demos/demo-8/products/product-9-2.jpg" alt="Product image"
                      class="product-image-hover">
                  </a>

                  <div class="product-action-vertical">
                    <a href="#" class="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                  </div><!-- End .product-action-vertical -->

                  <div class="product-action">
                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                  </div><!-- End .product-action -->
                </figure><!-- End .product-media -->

                <div class="product-body">
                  <div class="product-cat">
                    <a href="#">Clothing</a>
                  </div><!-- End .product-cat -->
                  <h3 class="product-title"><a href="product.html">BShort wrap dress</a></h3><!-- End .product-title -->
                  <div class="product-price">
                    $17.99
                  </div><!-- End .product-price -->
                </div><!-- End .product-body -->
              </div><!-- End .product -->
            </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->

            <div class="col-6 col-md-4 col-lg-3">
              <div class="product product-2 text-center">
                <figure class="product-media">
                  <a href="product.html">
                    <img src="assets/images/demos/demo-8/products/product-10-1.jpg" alt="Product image"
                      class="product-image">
                    <img src="assets/images/demos/demo-8/products/product-10-2.jpg" alt="Product image"
                      class="product-image-hover">
                  </a>

                  <div class="product-action-vertical">
                    <a href="#" class="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                  </div><!-- End .product-action-vertical -->

                  <div class="product-action">
                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                  </div><!-- End .product-action -->
                </figure><!-- End .product-media -->

                <div class="product-body">
                  <div class="product-cat">
                    <a href="#">Clothing</a>
                  </div><!-- End .product-cat -->
                  <h3 class="product-title"><a href="product.html">Biker jacket</a></h3><!-- End .product-title -->
                  <div class="product-price">
                    $34.99
                  </div><!-- End .product-price -->

                  <div class="product-nav product-nav-thumbs">
                    <a href="#" class="active">
                      <img src="assets/images/demos/demo-8/products/product-10-thumb.jpg" alt="product desc">
                    </a>
                    <a href="#">
                      <img src="assets/images/demos/demo-8/products/product-10-2-thumb.jpg" alt="product desc">
                    </a>
                  </div><!-- End .product-nav -->
                </div><!-- End .product-body -->
              </div><!-- End .product -->
            </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->

            <div class="col-6 col-md-4 col-lg-3">
              <div class="product product-2 text-center">
                <figure class="product-media">
                  <a href="product.html">
                    <img src="assets/images/demos/demo-8/products/product-11-1.jpg" alt="Product image"
                      class="product-image">
                    <img src="assets/images/demos/demo-8/products/product-11-2.jpg" alt="Product image"
                      class="product-image-hover">
                  </a>

                  <div class="product-action-vertical">
                    <a href="#" class="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                  </div><!-- End .product-action-vertical -->

                  <div class="product-action">
                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                  </div><!-- End .product-action -->
                </figure><!-- End .product-media -->

                <div class="product-body">
                  <div class="product-cat">
                    <a href="#">Shoes</a>
                  </div><!-- End .product-cat -->
                  <h3 class="product-title"><a href="product.html">Loafers</a></h3><!-- End .product-title -->
                  <div class="product-price">
                    $9.99
                  </div><!-- End .product-price -->
                </div><!-- End .product-body -->
              </div><!-- End .product -->
            </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->

            <div class="col-6 col-md-4 col-lg-3">
              <div class="product product-2 text-center">
                <figure class="product-media">
                  <span class="product-label label-sale">sale</span>
                  <a href="product.html">
                    <img src="assets/images/demos/demo-8/products/product-12-1.jpg" alt="Product image"
                      class="product-image">
                    <img src="assets/images/demos/demo-8/products/product-12-2.jpg" alt="Product image"
                      class="product-image-hover">
                  </a>

                  <div class="product-action-vertical">
                    <a href="#" class="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                  </div><!-- End .product-action-vertical -->

                  <div class="product-action">
                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                  </div><!-- End .product-action -->
                </figure><!-- End .product-media -->

                <div class="product-body">
                  <div class="product-cat">
                    <a href="#">Clothing</a>
                  </div><!-- End .product-cat -->
                  <h3 class="product-title"><a href="product.html">Super Skinny High Jeggings</a></h3>
                  <!-- End .product-title -->
                  <div class="product-price">
                    <span class="new-price">Now $12.99</span>
                    <span class="old-price">Was $17.99</span>
                  </div><!-- End .product-price -->
                </div><!-- End .product-body -->
              </div><!-- End .product -->
            </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->
          </div><!-- End .row -->
        </div><!-- End .products -->
      </div><!-- .End .tab-pane -->
      <div class="tab-pane p-0 fade" id="recent-women-tab" role="tabpanel" aria-labelledby="recent-women-link">
        <div class="products">
          <div class="row justify-content-center">
            <div class="col-6 col-md-4 col-lg-3">
              <div class="product product-2 text-center">
                <figure class="product-media">
                  <span class="product-label label-sale">Sale</span>
                  <a href="product.html">
                    <img src="assets/images/demos/demo-8/products/product-5-1.jpg" alt="Product image"
                      class="product-image">
                    <img src="assets/images/demos/demo-8/products/product-5-2.jpg" alt="Product image"
                      class="product-image-hover">
                  </a>

                  <div class="product-action-vertical">
                    <a href="#" class="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                  </div><!-- End .product-action-vertical -->

                  <div class="product-action">
                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                  </div><!-- End .product-action -->
                </figure><!-- End .product-media -->

                <div class="product-body">
                  <div class="product-cat">
                    <a href="#">Clothing</a>
                  </div><!-- End .product-cat -->
                  <h3 class="product-title"><a href="product.html">Tie-detail top</a></h3><!-- End .product-title -->
                  <div class="product-price">
                    <span class="new-price">Now $3.99</span>
                    <span class="old-price">Was $6.99</span>
                  </div><!-- End .product-price -->
                </div><!-- End .product-body -->
              </div><!-- End .product -->
            </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->

            <div class="col-6 col-md-4 col-lg-3">
              <div class="product product-2 text-center">
                <figure class="product-media">
                  <a href="product.html">
                    <img src="assets/images/demos/demo-8/products/product-6-1.jpg" alt="Product image"
                      class="product-image">
                    <img src="assets/images/demos/demo-8/products/product-6-2.jpg" alt="Product image"
                      class="product-image-hover">
                  </a>

                  <div class="product-action-vertical">
                    <a href="#" class="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                  </div><!-- End .product-action-vertical -->

                  <div class="product-action">
                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                  </div><!-- End .product-action -->
                </figure><!-- End .product-media -->

                <div class="product-body">
                  <div class="product-cat">
                    <a href="#">Shoes</a>
                  </div><!-- End .product-cat -->
                  <h3 class="product-title"><a href="product.html">Sandals</a></h3><!-- End .product-title -->
                  <div class="product-price">
                    $12.99
                  </div><!-- End .product-price -->
                </div><!-- End .product-body -->
              </div><!-- End .product -->
            </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->
          </div><!-- End .row -->
        </div><!-- End .products -->
      </div><!-- .End .tab-pane -->
      <div class="tab-pane p-0 fade" id="recent-men-tab" role="tabpanel" aria-labelledby="recent-men-link">
        <div class="products">
          <div class="row justify-content-center">
            <div class="col-6 col-md-4 col-lg-3">
              <div class="product product-2 text-center">
                <figure class="product-media">
                  <a href="product.html">
                    <img src="assets/images/demos/demo-8/products/product-11-1.jpg" alt="Product image"
                      class="product-image">
                    <img src="assets/images/demos/demo-8/products/product-11-2.jpg" alt="Product image"
                      class="product-image-hover">
                  </a>

                  <div class="product-action-vertical">
                    <a href="#" class="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                  </div><!-- End .product-action-vertical -->

                  <div class="product-action">
                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                  </div><!-- End .product-action -->
                </figure><!-- End .product-media -->

                <div class="product-body">
                  <div class="product-cat">
                    <a href="#">Shoes</a>
                  </div><!-- End .product-cat -->
                  <h3 class="product-title"><a href="product.html">Loafers</a></h3><!-- End .product-title -->
                  <div class="product-price">
                    $9.99
                  </div><!-- End .product-price -->
                </div><!-- End .product-body -->
              </div><!-- End .product -->
            </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->

            <div class="col-6 col-md-4 col-lg-3">
              <div class="product product-2 text-center">
                <figure class="product-media">
                  <span class="product-label label-sale">sale</span>
                  <a href="product.html">
                    <img src="assets/images/demos/demo-8/products/product-12-1.jpg" alt="Product image"
                      class="product-image">
                    <img src="assets/images/demos/demo-8/products/product-12-2.jpg" alt="Product image"
                      class="product-image-hover">
                  </a>

                  <div class="product-action-vertical">
                    <a href="#" class="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                  </div><!-- End .product-action-vertical -->

                  <div class="product-action">
                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                  </div><!-- End .product-action -->
                </figure><!-- End .product-media -->

                <div class="product-body">
                  <div class="product-cat">
                    <a href="#">Clothing</a>
                  </div><!-- End .product-cat -->
                  <h3 class="product-title"><a href="product.html">Super Skinny High Jeggings</a></h3>
                  <!-- End .product-title -->
                  <div class="product-price">
                    <span class="new-price">Now $12.99</span>
                    <span class="old-price">Was $17.99</span>
                  </div><!-- End .product-price -->
                </div><!-- End .product-body -->
              </div><!-- End .product -->
            </div>
          </div><!-- End .row -->
        </div><!-- End .products -->
      </div><!-- .End .tab-pane -->
      <div class="tab-pane p-0 fade" id="recent-shoes-tab" role="tabpanel" aria-labelledby="recent-shoes-link">
        <div class="products">
          <div class="row justify-content-center">
            <div class="col-6 col-md-4 col-lg-3">
              <div class="product product-2 text-center">
                <figure class="product-media">
                  <a href="product.html">
                    <img src="assets/images/demos/demo-8/products/product-7-1.jpg" alt="Product image"
                      class="product-image">
                    <img src="assets/images/demos/demo-8/products/product-7-2.jpg" alt="Product image"
                      class="product-image-hover">
                  </a>

                  <div class="product-action-vertical">
                    <a href="#" class="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                  </div><!-- End .product-action-vertical -->

                  <div class="product-action">
                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                  </div><!-- End .product-action -->
                </figure><!-- End .product-media -->

                <div class="product-body">
                  <div class="product-cat">
                    <a href="#">Bags</a>
                  </div><!-- End .product-cat -->
                  <h3 class="product-title"><a href="product.html">Small bucket bag</a></h3><!-- End .product-title -->
                  <div class="product-price">
                    $14.99
                  </div><!-- End .product-price -->

                  <div class="product-nav product-nav-thumbs">
                    <a href="#" class="active">
                      <img src="assets/images/demos/demo-8/products/product-7-thumb.jpg" alt="product desc">
                    </a>
                    <a href="#">
                      <img src="assets/images/demos/demo-8/products/product-7-2-thumb.jpg" alt="product desc">
                    </a>
                  </div><!-- End .product-nav -->
                </div><!-- End .product-body -->
              </div><!-- End .product -->
            </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->

            <div class="col-6 col-md-4 col-lg-3">
              <div class="product product-2 text-center">
                <figure class="product-media">
                  <a href="product.html">
                    <img src="assets/images/demos/demo-8/products/product-8-1.jpg" alt="Product image"
                      class="product-image">
                    <img src="assets/images/demos/demo-8/products/product-8-2.jpg" alt="Product image"
                      class="product-image-hover">
                  </a>

                  <div class="product-action-vertical">
                    <a href="#" class="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                  </div><!-- End .product-action-vertical -->

                  <div class="product-action">
                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                  </div><!-- End .product-action -->
                </figure><!-- End .product-media -->

                <div class="product-body">
                  <div class="product-cat">
                    <a href="#">Clothing</a>
                  </div><!-- End .product-cat -->
                  <h3 class="product-title"><a href="product.html">Denim jacket</a></h3><!-- End .product-title -->
                  <div class="product-price">
                    $34.99
                  </div><!-- End .product-price -->
                </div><!-- End .product-body -->
              </div><!-- End .product -->
            </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->

            <div class="col-6 col-md-4 col-lg-3">
              <div class="product product-2 text-center">
                <figure class="product-media">
                  <a href="product.html">
                    <img src="assets/images/demos/demo-8/products/product-9-1.jpg" alt="Product image"
                      class="product-image">
                    <img src="assets/images/demos/demo-8/products/product-9-2.jpg" alt="Product image"
                      class="product-image-hover">
                  </a>

                  <div class="product-action-vertical">
                    <a href="#" class="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                  </div><!-- End .product-action-vertical -->

                  <div class="product-action">
                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                  </div><!-- End .product-action -->
                </figure><!-- End .product-media -->

                <div class="product-body">
                  <div class="product-cat">
                    <a href="#">Clothing</a>
                  </div><!-- End .product-cat -->
                  <h3 class="product-title"><a href="product.html">BShort wrap dress</a></h3><!-- End .product-title -->
                  <div class="product-price">
                    $17.99
                  </div><!-- End .product-price -->
                </div><!-- End .product-body -->
              </div><!-- End .product -->
            </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->
          </div><!-- End .row -->
        </div><!-- End .products -->
      </div><!-- .End .tab-pane -->
    </div><!-- End .tab-content -->

    <div class="more-container text-center mt-3 mb-3">
      <a href="category.html" class="btn btn-outline-dark-3 btn-more"><span>View More</span><i
          class="icon-long-arrow-right"></i></a>
    </div><!-- End .more-container -->
  </div><!-- End .container -->



  <div class="cta bg-image bg-dark pt-6 pb-7 mb-5" style="background-image: url(assets/images/backgrounds/bg-1.jpg);">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-10 col-md-8 col-lg-6">
          <div class="cta-heading text-center">
            <h3 class="cta-title text-white">Join Our Newsletter</h3><!-- End .cta-title -->
            <p class="cta-desc text-light">Lorem ipsum dolor sit amet adipiscing.</p><!-- End .cta-desc -->
          </div><!-- End .text-center -->

          <form action="#">
            <div class="input-group">
              <input type="email" class="form-control" placeholder="Enter your Email Address" aria-label="Email Adress"
                required>
              <div class="input-group-append">
                <button class="btn btn-primary" type="submit"><span>Subscribe</span><i
                    class="icon-long-arrow-right"></i></button>
              </div><!-- .End .input-group-append -->
            </div><!-- .End .input-group -->
          </form>
        </div><!-- End .col-sm-10 col-md-8 col-lg-6 -->
      </div><!-- End .row -->
    </div><!-- End .container -->
  </div><!-- End .cta -->



  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-sm-6">
        <div class="icon-box icon-box-card text-center">
          <span class="icon-box-icon">
            <i class="icon-rocket"></i>
          </span>
          <div class="icon-box-content">
            <h3 class="icon-box-title">Payment & Delivery</h3><!-- End .icon-box-title -->
            <p>Free shipping for orders over $50</p>
          </div><!-- End .icon-box-content -->
        </div><!-- End .icon-box -->
      </div><!-- End .col-lg-4 col-sm-6 -->

      <div class="col-lg-4 col-sm-6">
        <div class="icon-box icon-box-card text-center">
          <span class="icon-box-icon">
            <i class="icon-rotate-left"></i>
          </span>
          <div class="icon-box-content">
            <h3 class="icon-box-title">Return & Refund</h3><!-- End .icon-box-title -->
            <p>Free 100% money back guarantee</p>
          </div><!-- End .icon-box-content -->
        </div><!-- End .icon-box -->
      </div><!-- End .col-lg-4 col-sm-6 -->

      <div class="col-lg-4 col-sm-6">
        <div class="icon-box icon-box-card text-center">
          <span class="icon-box-icon">
            <i class="icon-life-ring"></i>
          </span>
          <div class="icon-box-content">
            <h3 class="icon-box-title">Quality Support</h3><!-- End .icon-box-title -->
            <p>Alway online feedback 24/7</p>
          </div><!-- End .icon-box-content -->
        </div><!-- End .icon-box -->
      </div><!-- End .col-lg-4 col-sm-6 -->
    </div><!-- End .row -->
  </div><!-- End .container -->

  <div class="container instagram">
    <div class="heading text-center">
      <h2 class="title title-lg">Follow Us On Instagram</h2><!-- End .title -->
      <p class="title-desc">Stay updated with our social media activity!</p><!-- End .title-desc -->
      <div class="btn-wrap" style="margin-top: 10px;">
        <a target="_blank" href="https://www.instagram.com/amelieonline/"
          class="btn btn-outline-primary btn-round">Follow Now<i class="icon-long-arrow-right"></i></a>
      </div><!-- End .btn-wrap -->
    </div><!-- End .heading -->

    <div class="owl-carousel owl-simple" data-toggle="owl" data-owl-options='{
      "nav": false,
      "dots": false,
      "items": 6,
      "margin": 0,
      "loop": false,
      "responsive": {
          "0": {
              "items":1
          },
          "360": {
              "items":2
          },
          "600": {
              "items":3
          },
          "992": {
              "items":4
          },
          "1200": {
              "items":5
          },
          "1500": {
              "items":6
          }
      }
  }'>
      <div class="instagram-feed">
        <img src="assets/images/demos/demo-8/instagram/1.jpg" alt="img">

        <div class="instagram-feed-content">
          <a href="#"><i class="icon-heart-o"></i>466</a>
          <a href="#"><i class="icon-comments"></i>65</a>
        </div><!-- End .instagram-feed-content -->
      </div><!-- End .instagram-feed -->

      <div class="instagram-feed">
        <img src="assets/images/demos/demo-8/instagram/2.jpg" alt="img">

        <div class="instagram-feed-content">
          <a href="#"><i class="icon-heart-o"></i>39</a>
          <a href="#"><i class="icon-comments"></i>78</a>
        </div><!-- End .instagram-feed-content -->
      </div><!-- End .instagram-feed -->

      <div class="instagram-feed">
        <img src="assets/images/demos/demo-8/instagram/3.jpg" alt="img">

        <div class="instagram-feed-content">
          <a href="#"><i class="icon-heart-o"></i>691</a>
          <a href="#"><i class="icon-comments"></i>87</a>
        </div><!-- End .instagram-feed-content -->
      </div><!-- End .instagram-feed -->

      <div class="instagram-feed">
        <img src="assets/images/demos/demo-8/instagram/4.jpg" alt="img">

        <div class="instagram-feed-content">
          <a href="#"><i class="icon-heart-o"></i>508</a>
          <a href="#"><i class="icon-comments"></i>124</a>
        </div><!-- End .instagram-feed-content -->
      </div><!-- End .instagram-feed -->

      <div class="instagram-feed">
        <img src="assets/images/demos/demo-8/instagram/5.jpg" alt="img">

        <div class="instagram-feed-content">
          <a href="#"><i class="icon-heart-o"></i>433</a>
          <a href="#"><i class="icon-comments"></i>27</a>
        </div><!-- End .instagram-feed-content -->
      </div><!-- End .instagram-feed -->

      <div class="instagram-feed">
        <img src="assets/images/demos/demo-8/instagram/6.jpg" alt="img">

        <div class="instagram-feed-content">
          <a href="#"><i class="icon-heart-o"></i>122</a>
          <a href="#"><i class="icon-comments"></i>55</a>
        </div><!-- End .instagram-feed-content -->
      </div><!-- End .instagram-feed -->
    </div><!-- End .owl-carousel -->
  </div><!-- End .container -->


</main>
