import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscribable, Subscriber, Observable } from 'rxjs';
import { HelperService } from '../services/helpers.service';
import { disableDebugTools } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';

// import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { DialogboxComponent } from '../common/dialogbox/dialogbox.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  // public dialog: MatDialog
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private helperService: HelperService,

  ) { }

  sub;
  productCategory: string;
  products;
  isloading = false;
  displayCategory: string;

  ngOnInit(): void {
    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe((event: NavigationEnd) => {
    //   console.log('Routed:', event.url);
    // });

    this.sub = this.activatedRoute.params.subscribe(params => {
      this.productCategory = params.category;
      console.log('Product Category: ' + this.productCategory);
      this.loadproductDetails();
      this.setDisplayCategory();
    });
  }

  openModal() {
    // let dialogRef = this.dialog.open(DialogboxComponent, {
    //   height: '400px',
    //   width: '600px',
    // });
  }

  loadproductDetails() {
    this.isloading = true;
    this.helperService.GetNonSKUProducts().subscribe(
      (res) => {
        const items = [];
        // tslint:disable-next-line: no-string-literal
        res['Data'].forEach((item, index) => {
          items.push(item.prod_id);
        });
        // this.products = res['Data'];
        // this.isloading = false;
        this.helperService.GetProductDetails(items).subscribe(
          (res) => {
            this.products = res['Data'];
            this.isloading = false;
            // this.notifyService.showSuccess('Email added to our list!', '');
          },
          (err) => {
            console.log(err);
            this.isloading = false;
            // this.notifyService.showError('Data shown successfully !!', 'ItSolutionStuff.com');
          }
        );
      },
      (err) => {
        console.log(err);
        this.isloading = false;
        // this.notifyService.showError('Data shown successfully !!', 'ItSolutionStuff.com');
      }
    );

  }

  setDisplayCategory() {
    switch (this.productCategory) {
      case 'dresses': this.displayCategory = 'Dresses'; break;
      case 'tops': this.displayCategory = 'Tops'; break;
      case 'pants': this.displayCategory = 'Pants'; break;
      case 'skirts': this.displayCategory = 'Skirts'; break;
      case 'jumpsuites': this.displayCategory = 'Jump Suite'; break;
    }
  }

}
