import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ProductsComponent } from './products/products.component';
import { ComingsoonComponent } from './comingsoon/comingsoon.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { ProductdetailsComponent } from './productdetails/productdetails.component';
import { AuthGuardService } from './auth-guard.service';
import { WishlistComponent } from './wishlist/wishlist.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { MyaccountComponent } from './myaccount/myaccount.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'about',
        component: AboutUsComponent
      },
      {
        path: 'contact',
        component: ContactUsComponent
      },
      {
        path: 'products/:category',
        component: ProductsComponent
      },
      {
        path: 'products/:category/:productcode',
        component: ProductdetailsComponent
      },
      {
        path: 'wishlist',
        component: WishlistComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'cart',
        component: CartComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'checkout',
        component: CheckoutComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'myaccount',
        component: MyaccountComponent,
        canActivate: [AuthGuardService]
      }
    ]
  },
  {
    path: 'coming-soon',
    component: ComingsoonComponent,
  },
  { path: '**', component: PagenotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
