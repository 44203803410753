<main class="main">
  <div class="page-header text-center" style="background-image: url('assets/images/page-header-bg.jpg')">
    <div class="container">
      <h1 class="page-title">Checkout<span>Shop</span></h1>
    </div><!-- End .container -->
  </div><!-- End .page-header -->
  <nav aria-label="breadcrumb" class="breadcrumb-nav">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
        <li class="breadcrumb-item"><a href="#">Shop</a></li>
        <li class="breadcrumb-item active" aria-current="page">Checkout</li>
      </ol>
    </div><!-- End .container -->
  </nav><!-- End .breadcrumb-nav -->

  <div class="page-content">
    <div class="checkout">
      <div class="container">

        <!-- <div class="checkout-discount">
          <form action="#">
            <input type="text" class="form-control" required id="checkout-discount-input">
            <label for="checkout-discount-input" class="text-truncate">Have a coupon? <span>Click here to enter your
                code</span></label>
          </form>
        </div> -->

        <form #checkoutForm="ngForm" (ngSubmit)="proceedCheckout(checkoutForm)">
          <div class=" row">
            <div class="col-lg-9">
              <h2 class="checkout-title">Billing Details</h2><!-- End .checkout-title -->
              <div class="row">
                <div class="col-sm-6">
                  <label>Name *</label>
                  <input name="displayName" type="text" class="form-control" required [(ngModel)]="user.displayName">
                </div><!-- End .col-sm-6 -->

                <div class="col-sm-6">
                  <label>Email address *</label>
                  <input name="email" type="email" class="form-control" required [(ngModel)]="user.email">
                </div><!-- End .col-sm-6 -->
              </div><!-- End .row -->

              <label>Company Name (Optional)</label>
              <input name="companyName" type="text" class="form-control" [(ngModel)]="user.companyName">
              <!--
              <label>Country *</label>
              <input type="text" class="form-control" required> -->

              <label>Street address *</label>
              <input name="addressline01" type="text" class="form-control" placeholder="House number and Street name"
                required [(ngModel)]="user.addressline01">
              <input name="addressline02" type="text" class="form-control"
                placeholder="Appartments, suite, unit etc ..." required [(ngModel)]="user.addressline02">

              <div class="row">
                <div class="col-sm-6">
                  <label>Town / City *</label>
                  <input name="city" type="text" class="form-control" required [(ngModel)]="user.city">
                </div><!-- End .col-sm-6 -->

                <div class="col-sm-6">
                  <label>State / County *</label>
                  <input name="country" type="text" class="form-control" required [(ngModel)]="user.country">
                </div><!-- End .col-sm-6 -->
              </div><!-- End .row -->

              <div class="row">
                <div class="col-sm-6">
                  <label>Postcode / ZIP *</label>
                  <input name="zipcode" type="text" class="form-control" required [(ngModel)]="user.zipcode">
                </div><!-- End .col-sm-6 -->

                <div class="col-sm-6">
                  <label>Phone *</label>
                  <input name="phone" type="tel" class="form-control" required [(ngModel)]="user.phone">
                </div><!-- End .col-sm-6 -->
              </div><!-- End .row -->



              <!-- <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="checkout-create-acc">
                <label class="custom-control-label" for="checkout-create-acc">Create an account?</label>
              </div>

              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="checkout-diff-address">
                <label class="custom-control-label" for="checkout-diff-address">Ship to a different address?</label>
              </div> -->

              <label>Order notes (optional)</label>
              <textarea name="othernotes" [(ngModel)]="user.othernotes" class="form-control" cols="30" rows="4"
                placeholder="Notes about your order, e.g. special notes for delivery"></textarea>
            </div><!-- End .col-lg-9 -->
            <aside class="col-lg-3">
              <div class="summary">
                <h3 class="summary-title">Your Order</h3><!-- End .summary-title -->

                <table class="table table-summary">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Total</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr *ngFor="let item of cartitems; let i=index;">
                      <td><a href=" javascript:void(0)"
                          [routerLink]="['/products', item.prod_type.toLowerCase(), item.prod_code]">{{item.prod_name}}
                          x {{item.qty}}</a>
                      </td>
                      <td>LKR {{item.prod_pricewotax}}</td>
                    </tr>


                    <tr class="summary-shipping">
                      <td>Shipping:</td>
                      <td>&nbsp;</td>
                    </tr>

                    <tr class="summary-shipping-row">
                      <td>
                        <div class="custom-control custom-radio">
                          <input type="radio" id="free-shipping" name="shipping" class="custom-control-input">
                          <label class="custom-control-label" for="free-shipping">Free Shipping</label>
                        </div>
                      </td>
                      <td>LKR 0.00</td>
                    </tr>

                    <tr class="summary-shipping-row">
                      <td>
                        <div class="custom-control custom-radio">
                          <input type="radio" id="standart-shipping" name="shipping" class="custom-control-input">
                          <label class="custom-control-label" for="standart-shipping">Standart:</label>
                        </div>
                      </td>
                      <td>LKR 10.00</td>
                    </tr>

                    <tr class="summary-shipping-row">
                      <td>
                        <div class="custom-control custom-radio">
                          <input type="radio" id="express-shipping" name="shipping" class="custom-control-input">
                          <label class="custom-control-label" for="express-shipping">Express:</label>
                        </div>
                      </td>
                      <td>LKR 20.00</td>
                    </tr>


                    <tr class="summary-subtotal">
                      <td>Subtotal:</td>
                      <td>LKR {{totalamount}}</td>
                    </tr><!-- End .summary-subtotal -->
                    <tr>
                      <td>Shipping:</td>
                      <td>Free shipping</td>
                    </tr>
                    <tr class="summary-total">
                      <td>Total:</td>
                      <td>LKR 160.00</td>
                    </tr><!-- End .summary-total -->
                  </tbody>
                </table><!-- End .table table-summary -->

                <div class="accordion-summary" id="accordion-payment">
                  <div class="card">
                    <div class="card-header" id="heading-1">
                      <h2 class="card-title">
                        <a role="button" data-toggle="collapse" href="#collapse-1" aria-expanded="true"
                          aria-controls="collapse-1">
                          Direct bank transfer
                        </a>
                      </h2>
                    </div><!-- End .card-header -->
                    <div id="collapse-1" class="collapse show" aria-labelledby="heading-1"
                      data-parent="#accordion-payment">
                      <div class="card-body">
                        Make your payment directly into our bank account. Please use your Order ID as the payment
                        reference. Your order will not be shipped until the funds have cleared in our account.
                      </div><!-- End .card-body -->
                    </div><!-- End .collapse -->
                  </div><!-- End .card -->

                  <div class="card">
                    <div class="card-header" id="heading-2">
                      <h2 class="card-title">
                        <a class="collapsed" role="button" data-toggle="collapse" href="#collapse-2"
                          aria-expanded="false" aria-controls="collapse-2">
                          Check payments
                        </a>
                      </h2>
                    </div><!-- End .card-header -->
                    <div id="collapse-2" class="collapse" aria-labelledby="heading-2" data-parent="#accordion-payment">
                      <div class="card-body">
                        Ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros.
                        Nullam malesuada erat ut turpis.
                      </div><!-- End .card-body -->
                    </div><!-- End .collapse -->
                  </div><!-- End .card -->

                  <div class="card">
                    <div class="card-header" id="heading-3">
                      <h2 class="card-title">
                        <a class="collapsed" role="button" data-toggle="collapse" href="#collapse-3"
                          aria-expanded="false" aria-controls="collapse-3">
                          Cash on delivery
                        </a>
                      </h2>
                    </div><!-- End .card-header -->
                    <div id="collapse-3" class="collapse" aria-labelledby="heading-3" data-parent="#accordion-payment">
                      <div class="card-body">Quisque volutpat mattis eros. Lorem ipsum dolor sit amet, consectetuer
                        adipiscing elit. Donec odio. Quisque volutpat mattis eros.
                      </div><!-- End .card-body -->
                    </div><!-- End .collapse -->
                  </div><!-- End .card -->

                  <div class="card">
                    <div class="card-header" id="heading-4">
                      <h2 class="card-title">
                        <a class="collapsed" role="button" data-toggle="collapse" href="#collapse-4"
                          aria-expanded="false" aria-controls="collapse-4">
                          PayPal <small class="float-right paypal-link">What is PayPal?</small>
                        </a>
                      </h2>
                    </div><!-- End .card-header -->
                    <div id="collapse-4" class="collapse" aria-labelledby="heading-4" data-parent="#accordion-payment">
                      <div class="card-body">
                        Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a,
                        pede. Donec nec justo eget felis facilisis fermentum.
                      </div><!-- End .card-body -->
                    </div><!-- End .collapse -->
                  </div><!-- End .card -->

                  <div class="card">
                    <div class="card-header" id="heading-5">
                      <h2 class="card-title">
                        <a class="collapsed" role="button" data-toggle="collapse" href="#collapse-5"
                          aria-expanded="false" aria-controls="collapse-5">
                          Credit Card (Stripe)
                          <img src="assets/images/payments-summary.png" alt="payments cards">
                        </a>
                      </h2>
                    </div><!-- End .card-header -->
                    <div id="collapse-5" class="collapse" aria-labelledby="heading-5" data-parent="#accordion-payment">
                      <div class="card-body"> Donec nec justo eget felis facilisis fermentum.Lorem ipsum dolor sit amet,
                        consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Lorem ipsum dolor sit
                        ame.
                      </div><!-- End .card-body -->
                    </div><!-- End .collapse -->
                  </div><!-- End .card -->
                </div><!-- End .accordion -->

                <button type="submit" class="btn btn-outline-primary-2 btn-order btn-block">
                  <span *ngIf="isloading === false" class="btn-text">Place Order</span>
                  <span *ngIf="isloading === false" class="btn-hover-text">Proceed to Checkout</span>
                  <img *ngIf="isloading === true" style="width:15px; height:15px;" src="assets/loader-white.svg" alt="">
                </button>
              </div><!-- End .summary -->
            </aside><!-- End .col-lg-3 -->
          </div><!-- End .row -->
        </form>
      </div><!-- End .container -->
    </div><!-- End .checkout -->
  </div><!-- End .page-content -->
</main><!-- End .main -->
